import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      width: '62px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      border: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: palette.primary.main
      }
    },
    angleIcon: {
      width: '14px',
      height: '16px',
      transition: 'transform 0.3s ease'
    },
    rotatedAngleIcon: {
      transform: 'rotate(180deg)'
    },
    menu: {
      marginTop: '48px',
      width: '220px',
      '& .MuiMenuItem-root': {
        padding: '8px 16px'
      }
    },
    userInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '4px 16px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    },
    userName: {
      fontFamily: 'Inter',
      fontSize: '16px',
      padding: 0,
      letterSpacing: 0
    },
    userEmail: {
      fontFamily: 'Inter',
      fontSize: '14px',
      color: palette.text.disabled,
      padding: 0,
      letterSpacing: 0
    },
    userProfileIcon: {
      backgroundColor: palette.primary.main
    },
    divider: {
      margin: '8px 0'
    }
  }
})

export const useUserMenuDropdownStyles = () => {
  const classes = useStyles()

  return { classes }
}
