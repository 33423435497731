import { Chip as MaterialChip } from '@material-ui/core'

import { useChipStyles } from './hooks'

export const Chip = ({
  variant = 'outlined',
  size = 'medium',
  type = 'treatment',
  label,
  value,
  color,
  onClick,
  onDelete,
  className
}) => {
  const isClickable = onClick || onDelete
  const { joinedClasses } = useChipStyles({ className, type, isClickable })

  return (
    <MaterialChip
      variant={variant}
      size={size}
      label={label}
      onClick={
        onClick
          ? (e) => {
              onClick(value, e)
            }
          : undefined
      }
      onDelete={
        onDelete
          ? () => {
              onDelete(value)
            }
          : undefined
      }
      color={color}
      clickable={false}
      className={joinedClasses}
    />
  )
}
