import { List, Typography, Box } from '@material-ui/core'

import { LocationListItem, UseMyLocationButton, LocationUnavailableBanner } from './components'
import { useLocationResultListStyles } from './hooks'

export const LocationResultList = ({
  results,
  locationInputValue,
  onItemClick,
  onUseMyLocationClick,
  isFetchLocationError,
  onMouseDown
}) => {
  const { classes } = useLocationResultListStyles()

  const renderListItems = () => {
    return results.map((location, index) => {
      const city = location.text
      const context = location.context
      const state = context ? context[0].text : undefined
      const coordinates = {
        lat: location.geometry.coordinates[1],
        lon: location.geometry.coordinates[0]
      }

      return (
        <LocationListItem
          city={city}
          state={state}
          coordinates={coordinates}
          locationInputValue={locationInputValue}
          onClick={onItemClick}
          key={index}
        />
      )
    })
  }

  const shouldRenderLocations = results !== undefined && results.length > 0

  return (
    <Box className={classes.container} onMouseDown={onMouseDown}>
      {isFetchLocationError ? (
        <LocationUnavailableBanner />
      ) : (
        <UseMyLocationButton onClick={onUseMyLocationClick} />
      )}
      {shouldRenderLocations ? (
        <Box className={classes.listItemsContainer}>
          <Typography className={classes.headerText}>Suggested</Typography>
          <List className={classes.listItemsWrapper}>{renderListItems()}</List>
        </Box>
      ) : (
        <Box className={classes.listItemsContainer}>
          <Typography className={classes.headerText}>No locations found</Typography>
          <Typography className={classes.noLocationsFoundText}>
            Sorry, we couldn't find any locations matching your search.
          </Typography>
        </Box>
      )}
    </Box>
  )
}
