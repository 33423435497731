import { Box, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'

import {
  DesktopConsultationListItem,
  MobileConsultationListItem,
  PractitionerListItem
} from './components'
import { useConsultationResultListStyles } from './hooks'

import {
  getClinicName,
  getListingId,
  getLocationName,
  getPractitionerName,
  getTitle
} from '../../../utils/practitionerListing'

import { AnalyticsEvent, trackEvent } from '../../../../../analytics/handlers'
import { consultationIndex } from '../../../../../lib/algolia'

export const ConsultationResultList = ({
  results,
  platform,
  consultationInputValue,
  onItemClick,
  onMouseDown
}) => {
  const [allConsultations, setAllConsultations] = useState({ hits: [] })
  const { consultation, listing } = results
  const { classes } = useConsultationResultListStyles()

  useEffect(() => {
    ;(async () => {
      try {
        const allConsultations = await consultationIndex.search('')
        setAllConsultations(allConsultations)
      } catch (err) {
        console.error('Failed to fetch consultations from Algolia', err)
        setAllConsultations({ hits: [] })
      }
    })()
  }, [])

  const renderMobileConsultationListItems = (allConsultations) => {
    const targetConsultations = allConsultations || consultation
    return targetConsultations.hits.map((hit) => {
      return (
        <MobileConsultationListItem
          consultation={hit.name}
          consultationId={hit.id}
          category={hit.category}
          consultationInputValue={consultationInputValue}
          onClick={onItemClick}
          key={hit.name}
        />
      )
    })
  }

  const renderDesktopConsultationListItems = (allConsultations) => {
    const targetConsultations = allConsultations || consultation
    return targetConsultations.hits.map((hit) => {
      return (
        <DesktopConsultationListItem
          consultation={hit.name}
          consultationId={hit.id}
          category={hit.category}
          consultationInputValue={consultationInputValue}
          onClick={onItemClick}
          key={hit.name}
        />
      )
    })
  }

  const renderPractitionersAndClinicsListItems = () => {
    return listing.hits.map((listing, index) => {
      const id = getListingId(listing)
      const title = getTitle(listing)
      const location = getLocationName(listing)?.address
      const clinic = getClinicName(listing)
      const name = getPractitionerName(listing)

      const clickHandler = () => {
        trackEvent(AnalyticsEvent.consultationProfileTapped, { listingId: listing.id })
      }

      return (
        <PractitionerListItem
          name={name}
          clinic={clinic}
          location={location}
          listingId={id}
          listingTitle={title}
          consultationInputValue={consultationInputValue}
          onClick={clickHandler}
          key={`${name}${index}`}
        />
      )
    })
  }

  const shouldRenderConsultations = consultation.hits.length > 0 || listing.hits.length > 0
  const shouldRenderPractitioners = listing.hits.length > 0
  const shouldRenderAllConsultations = allConsultations.hits.length > 0

  const renderResultList = () => {
    return (
      <Box className={classes.container} onMouseDown={onMouseDown}>
        {shouldRenderConsultations ? (
          consultation.hits.length > 0 ? (
            <Box className={classes.categoryContainer}>
              <Typography className={classes.categoryHeader}>Consultations</Typography>

              {platform === 'desktop' ? (
                <Box className={classes.listItemsContainer}>
                  {renderDesktopConsultationListItems()}
                </Box>
              ) : (
                <Box className={classes.mobileChipsItemsContainer}>
                  {renderMobileConsultationListItems()}
                </Box>
              )}
            </Box>
          ) : null
        ) : (
          <Box className={classes.noConsultationsFoundContainer}>
            {consultationInputValue.length > 0 && (
              <Box>
                <Typography className={classes.categoryHeader}>No matches found</Typography>
                <Typography className={classes.noConsultationsFoundText}>
                  Try a different search or explore our consultations
                </Typography>
              </Box>
            )}
            {shouldRenderAllConsultations && (
              <Box className={classes.categoryContainer}>
                <Typography className={classes.categoryHeader}>Consultations</Typography>
                {platform === 'desktop' ? (
                  <Box className={classes.listItemsContainer}>
                    {renderDesktopConsultationListItems(allConsultations)}
                  </Box>
                ) : (
                  <Box className={classes.mobileChipsItemsContainer}>
                    {renderMobileConsultationListItems(allConsultations)}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
        {shouldRenderPractitioners && (
          <Box className={classes.categoryContainer}>
            <Typography className={classes.categoryHeader}>Practitioners and clinics</Typography>
            <Box className={classes.listItemsContainer}>
              {renderPractitionersAndClinicsListItems()}
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  return renderResultList()
}
