import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: 'Inter',
      color: palette.button.text.tertiary,
      textDecoration: 'none !important',
      padding: '8px 16px',
      borderRadius: '4px',
      '&:hover': {
        color: palette.primary.main
      },
      '&:active': {
        color: palette.primary.dark
      }
    },
    activeContainer: {
      backgroundColor: palette.primary.light,
      color: palette.primary.main
    }
  }
})

export const useMenuItemStyles = ({ isActive, className }) => {
  const classes = useStyles()
  const joinedClasses = classNames(
    classes.container,
    isActive && classes.activeContainer,
    className
  )

  return { joinedClasses }
}
