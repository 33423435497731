import { ImageList, ImageListItem } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  marginTop: '10px',
  marginBottom: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744',
  color: '#ff1744'
}

const imageContainerStyle = {
  margin: '10px',
  position: 'relative',
  width: '100px',
  height: '100px'
}

const imageStyle = {
  width: '100px',
  height: '100px',
  objectFit: 'contain' // This ensures aspectFit
}

const deleteButtonStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: '#FF7070', // Or any color you prefer
  color: 'white',
  border: 'none',
  cursor: 'pointer'
}

const Dropzone = ({
  onDrop,
  placeholder,
  files,
  accept = 'image/*',
  showFiles = true,
  maxNumFiles = 6
}) => {
  const [displayFiles, setDisplayFiles] = useState([])

  useEffect(() => {
    setDisplayFiles(
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    )
  }, [files])

  const handleDelete = (fileToDelete) => {
    const updatedFiles = files.filter((file) => file !== fileToDelete)
    onDrop(updatedFiles)
  }

  const sizeInMB = 15
  const mbToBytes = (mb) => mb * 1024 * 1024
  const maxSize = mbToBytes(sizeInMB)
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, fileRejections } =
    useDropzone({
      accept: { [accept]: [] },
      onDrop: (acceptedFiles) => {
        const updatedFiles = [...files, ...acceptedFiles]
        onDrop(updatedFiles)
      },
      validator: (file) => {
        if (displayFiles.length >= maxNumFiles) {
          return {
            code: 'too-many-files',
            message: `You can only upload ${maxNumFiles} images`
          }
        }
      },
      maxSize: maxSize,
      maxFiles: maxNumFiles
    })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(fileRejections.length > 0 ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const handleFileRejections = (fileRejections) => {
    const filesTooLarge = fileRejections.filter((f) =>
      f.errors.some((e) => e.code === 'file-too-large')
    )

    const filesTooLargeComponent = filesTooLarge.map((rejection) => (
      <div style={rejectStyle}>
        {rejection.file.name} is too large. Each image must be less than {sizeInMB} MB
      </div>
    ))

    const tooManyFiles = fileRejections.filter((f) =>
      f.errors.some((e) => e.code === 'too-many-files')
    )
    const tooManyFilesComponent =
      tooManyFiles.length > 0 ? (
        <div style={rejectStyle}>Please upload a maximum of {maxNumFiles} images</div>
      ) : null

    const unsupportedFileType = fileRejections.filter((f) =>
      f.errors.some((e) => e.code === 'file-invalid-type')
    )
    const unsupportedFileTypeComponent = unsupportedFileType.map((rejection) => (
      <div style={rejectStyle}>
        {rejection.file.name} is not the right format. Please upload a JPG, JPEG, or PNG
      </div>
    ))

    return (
      <div style={{ marginBottom: '20px', marginTop: '20px' }}>
        {filesTooLargeComponent}
        {tooManyFilesComponent}
        {unsupportedFileTypeComponent}
      </div>
    )
  }

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{placeholder}</p>
      </div>
      {fileRejections.length > 0 ? handleFileRejections(fileRejections) : null}
      {showFiles && displayFiles.length > 0 && (
        <div>
          <p>Adding Photos</p>
          <ImageList cols={2} rowHeight={164}>
            {displayFiles.map((file, index) => (
              <ImageListItem key={file.name + '-' + index}>
                <img
                  srcSet={file.preview}
                  src={file.preview}
                  alt={file.name + '-' + index}
                  loading="lazy"
                />
                <button
                  style={deleteButtonStyle}
                  onClick={(e) => {
                    handleDelete(file)
                    e.preventDefault()
                  }}
                >
                  X
                </button>
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      )}
    </div>
  )
}

export default Dropzone
