export const asiCourseList = [
  {
    name: 'AFI - Complete Injector Online Bundle',
    id: 'afi-complete-injector-online-bundle'
  },
  {
    name: 'AFI - Intermediate - Advanced Injector Online Bundle',
    id: 'afi-intermediate-advanced-injector-online-bundle'
  },
  {
    name: 'AFI - Intermediate - Intermediate to Advanced Anti-wrinkle Injections',
    id: 'afi-intermediate-intermediate-to-advanced-antiwrinkle-injections'
  },
  {
    name: 'AFI - Complex Dermal Filler',
    id: 'afi-complex-dermal-filler'
  },
  {
    name: 'AFI - Foundation Dermal Filler',
    id: 'afi-foundation-dermal-filler'
  },
  {
    name: 'AFI - Complications and Vascular Emergencies',
    id: 'afi-complications-and-vascular-emergencies'
  },
  {
    name: 'AFI - Advanced Dermal Filler',
    id: 'afi-advanced-dermal-filler'
  },
  {
    name: 'AFI - Foundation Anti-wrinkle Injections',
    id: 'afi-foundation-antiwrinkle-injections'
  },
  {
    name: 'Mesotherapy',
    id: 'mesotherapy'
  },
  {
    name: 'Skin Booster',
    id: 'skin-booster'
  },
  {
    name: 'Lipodisolve',
    id: 'lipodisolve'
  },
  {
    name: 'Liquid Gold PRP and PRF',
    id: 'liquid-gold-prp-and-prf'
  },
  {
    name: 'PRP For Female Sexual Dysfunction',
    id: 'prp-for-female-sexual-dysfunction'
  },
  {
    name: 'PRP For Male Sexual Dysfunction',
    id: 'prp-for-male-sexual-dysfunction'
  },
  {
    name: 'Hair Regrowth',
    id: 'hair-regrowth'
  },
  {
    name: 'IV Infusion',
    id: 'iv-infusion'
  },
  {
    name: 'Profhilo',
    id: 'profhilo'
  },
  {
    name: 'Seventy Hyal',
    id: 'seventy-hyal'
  },
  {
    name: 'Advanced Cosmetic Long Threads',
    id: 'advanced-cosmetic-long-threads'
  },
  {
    name: 'Cosmetic Mono Threads',
    id: 'cosmetic-mono-threads'
  },
  {
    name: 'Diploma in Aesthetic Medicine',
    id: 'diploma-in-aesthetic-medicine'
  },
  {
    name: 'Diploma in Cosmetic Injectables and Procedures',
    id: 'diploma-in-cosmetic-injectables-and-procedures'
  },
  {
    name: 'Diploma in Dermal Science and Therapy',
    id: 'diploma-in-dermal-science-and-therapy'
  },
  {
    name: 'Advanced Neurotoxin Training Program',
    id: 'advanced-neurotoxin-training-program'
  },
  {
    name: 'Filler Dissolving Masterclass for Emergency Setting',
    id: 'filler-dissolving-masterclass-for-emergency-setting'
  },
  {
    name: 'Cadaver Workshop: Facial Anatomy Exploration and Dissection',
    id: 'cadaver-workshop-facial-anatomy-exploration-and-dissection'
  }
]

export const cpdCourseList = [
  {
    name: '2 day Foundation Neurotoxin Injectables',
    id: '2-day-foundation-neurotoxin-injectables'
  },
  {
    name: '2 day Foundation Dermal Filler Injectables',
    id: '2-day-foundation-dermal-filler-injectables'
  },
  {
    name: '4 day Fundamentals in Cosmetic Injectables',
    id: '4-day-fundamentals-in-cosmetic-injectables'
  },
  {
    name: 'Fundamentals in Cosmetic Dermal Practice',
    id: 'fundamentals-in-cosmetic-dermal-practice'
  },
  {
    name: 'Advanced Aesthetic Practitioner',
    id: 'advanced-aesthetic-practitioner'
  },
  {
    name: 'Hyaluronidase in Aesthetic Practice & Vascular Occlusion Management 2024',
    id: 'hyaluronidase-in-aesthetic-practice-vascular-occlusion-management-2024'
  },
  {
    name: 'Platelet - Rich Plasma (PRP)',
    id: 'platelet-rich-plasma'
  },
  {
    name: 'Mesotherapy',
    id: 'mesotherapy'
  },
  {
    name: 'PDO Mono Threads',
    id: 'pdo-mono-threads'
  },
  {
    name: 'Advanced Skin Science',
    id: 'advanced-skin-science'
  }
]

export const accsdsCourseList = [
  {
    name: 'Graduate Diploma of Cosmetic Nursing and Injectables',
    id: 'graduate-diploma-of-cosmetic-nursing-and-injectables'
  }
]

export const dermaInstituteCourseList = [
  {
    name: 'Foundation Botox and Dermal Filler Trainin (1 day)',
    id: 'foundation-botox-and-dermal-filler-trainin'
  },
  {
    name: 'Advanced Botox and Dermal Filler Training (1 day)',
    id: 'advanced-botox-and-dermal-filler-training'
  },
  {
    name: 'Combined Botox and Dermal Filler Training (3 days)',
    id: 'combined-botox-and-dermal-filler-training'
  },
  {
    name: 'Skin Boosters',
    id: 'skin-boosters'
  },
  {
    name: 'Polynucleotydes Training Course',
    id: 'polynucleotydes-training-course'
  },
  {
    name: 'Skin Boosters & Polynucleotydes - 2 day Training Package',
    id: 'skin-boosters-polynucleotydes-2-day-training-package'
  },
  {
    name: 'Lip Filler Masterclass (1 day)',
    id: 'lip-filler-masterclass'
  },
  {
    name: 'Facial Mastery Certificate (1 day)',
    id: 'facial-mastery-certificate'
  }
]

export const aimaAustraliaCourseList = [
  {
    name: 'Antiwrinkle & Dermal Filler Cosmetic Injecting Course',
    id: 'antiwrinkle-dermal-filler-cosmetic-injecting-course'
  }
]

export const dermaMedicalCourseList = [
  {
    name: 'Foundation Cosmetic Injectables Training',
    id: 'foundation-cosmetic-injectables-training'
  },
  { name: 'Advanced Cosmetic Injectables Training', id: 'advanced-cosmetic-injectables-training' },
  { name: 'Lip Filler Masterclass', id: 'lip-filler-masterclass' },
  { name: 'Full Facial Contouring Masterclass', id: 'full-facial-contouring-masterclass' }
]

export const juvaeCourseList = [
  { name: 'Clinical Foundations Training', id: 'clinical-foundations-training' }
]

export const freshClinicsCourseList = [
  { name: 'Fresh Bootcamp Training', id: 'fresh-bootcamp-training' },
  { name: 'Antiwrinkle off-label and Fat Dissolve', id: 'antiwrinkle-off-label-and-fat-dissolve' },
  { name: 'Mid and Lower Face Cannula', id: 'mid-and-lower-face-cannula' },
  { name: 'Bootcamp & Skin bootcamp', id: 'bootcamp-skin-bootcamp' },
  { name: 'Antiwrinkle off-label: Beginner', id: 'antiwrinkle-off-label-beginner' },
  { name: 'Antiwrinkle off-label: Intermediate', id: 'antiwrinkle-off-label-intermediate' },
  {
    name: 'Collagen Stimulating PDO Body: Intermediate',
    id: 'collagen-stimulating-pdo-body-intermediate'
  },
  { name: 'Collagen Stimulating: Beginner', id: 'collagen-stimulating-beginner' },
  { name: 'Collagen Stimulating: Advanced', id: 'collagen-stimulating-advanced' },
  { name: 'Fat Dissolve: Beginner', id: 'fat-dissolve-beginner' },
  { name: 'Fat Dissolve: Intermediate', id: 'fat-dissolve-intermediate' },
  { name: 'Hand Rejuvenation: Beginner', id: 'hand-rejuvenation-beginner' },
  { name: 'Hand Rejuvenation: Intermediate', id: 'hand-rejuvenation-intermediate' },
  { name: 'Lip Masterclass', id: 'lip-masterclass' },
  { name: 'Lower Face Cannula: Intermediate', id: 'lower-face-cannula-intermediate' },
  { name: 'Mentor and Assessment Course', id: 'mentor-and-assessment-course' },
  { name: 'Mid Face Cannula: Intermediate', id: 'mid-face-cannula-intermediate' },
  { name: 'Upper Face Cannula: Advanced', id: 'upper-face-cannula-advanced' },
  { name: 'Advanced Treatment Planning Masterclass', id: 'advanced-treatment-planning-masterclass' }
]

export const allCourseList = [
  {
    id: 'asi',
    trainingProvider: 'Aesthetics and Skin Institute (ASI)',
    description:
      'The Aesthetics and Skin Institute (ASI) is a global training organisation based in Australia.',
    courses: asiCourseList
  },
  {
    id: 'cpd',
    trainingProvider: 'Cosmetic Professional Development Institute of Australia (CPD)',
    description:
      'CPD Institute of Australia is a leader in cosmetic and injectables training for clinical professionals.',
    courses: cpdCourseList
  },
  {
    id: 'accds',
    trainingProvider: 'AACDS',
    description:
      'AACDS is a Registered Training Organisation (RTO) established in 2004 to deliver nationally accredited qualifications in cosmetic nursing and dermal therapies.',
    courses: accsdsCourseList
  },
  {
    id: 'dermaInstitute',
    trainingProvider: 'Derma Institute',
    description:
      'Derma Institute is an award-winning training academy that specialises in beginners and advanced practitioners.',
    courses: dermaInstituteCourseList
  },
  {
    id: 'aimaAustralia',
    trainingProvider: 'AIMA Australia',
    description:
      'AIMA offers the most comprehensive training program in cosmetic injectables training.',
    courses: aimaAustraliaCourseList
  },
  {
    id: 'dermaMedical',
    trainingProvider: 'Derma Medical',
    description:
      'Derma Medical is a worldwide training centre of excellence in aesthetic medicine offering Injectables & dermal fillers training courses.',
    courses: dermaMedicalCourseList
  },
  {
    id: 'juvae',
    trainingProvider: "Juv'ae",
    description:
      "Juv'ae Cosmetic Nurses are trained by some of the most esteemed cosmetic injectors to the highest standard of practice.",
    courses: juvaeCourseList
  },
  {
    id: 'freshClinics',
    trainingProvider: 'Fresh Clinics',
    description:
      'Our courses are designed to satisfy the latest guidelines for aesthetic medicine.',
    courses: freshClinicsCourseList
  }
]

export const combinedCourseList = allCourseList.flatMap((provider) =>
  provider.courses.map((course) => ({
    id: course.id,
    subLabel: provider.trainingProvider,
    trainingProviderId: provider.id,
    name: course.name
  }))
)

export const getCourseDetails = (orgName, courseId) => {
  const organization = allCourseList.find((org) => org.id === orgName)

  if (organization) {
    const course = organization.courses.find((course) => course.id === courseId)

    if (course) {
      return {
        courseName: course.name,
        organizationName: organization.trainingProvider
      }
    } else {
      return `Course with ID "${courseId}" not found in organization "${orgName}".`
    }
  } else {
    return `Organization with ID "${orgName}" not found.`
  }
}
