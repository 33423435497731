import { Avatar as MaterialAvatar } from '@material-ui/core'

import { useAvatarStyles } from './hooks'

export const Avatar = ({ imageUrl }) => {
  const { classes } = useAvatarStyles()

  if (imageUrl) {
    return <MaterialAvatar src={imageUrl} />
  } else {
    return <MaterialAvatar className={classes.defaultAvatar} />
  }
}
