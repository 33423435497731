import classNames from 'classnames'
import { string } from 'prop-types'
import React from 'react'
import css from './Chip.module.css'
const Chip = ({ label, color, icon, textColor, borderColor, rootClassName }) => {
  // Inline styles for dynamic background color, text color, and border color
  const chipStyle = {
    backgroundColor: color,
    color: textColor,
    border: borderColor ? `1px solid ${borderColor}` : `1px solid ${color}`
  }

  const classes = classNames(css.chip, rootClassName)

  return (
    <div className={classes} style={chipStyle}>
      {icon}
      {label}
    </div>
  )
}

Chip.defaultProps = {
  rootClassName: null,
  textColor: 'white', // Default text color
  borderColor: null // Default to no border
}

Chip.propTypes = {
  label: string.isRequired,
  color: string.isRequired,
  textColor: string,
  borderColor: string,
  rootClassName: string
}

export default Chip
