import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { ValidationError } from '../../components'

import { faRobot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Chip from '../Chip/Chip'
import css from './FieldSelect.module.css'

const FieldSelectComponent = (props) => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    subLabel = null,
    postLabel = null,
    required,
    showChip,
    writerIcon,
    input,
    meta,
    useMobileMargins,
    children,
    onChange,
    ...rest
  } = props

  if (label && !id) {
    throw new Error('id required when a label is given')
  }

  const { valid, invalid, touched, error } = meta

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error

  const selectClasses = classNames({
    [selectClassName]: selectClassName,
    [css.selectError]: hasError
  })
  const handleChange = (e) => {
    input.onChange(e)
    if (onChange) {
      onChange(e.currentTarget.value)
    }
  }

  const selectProps = {
    className: selectClasses,
    id,
    ...input,
    onChange: handleChange,
    ...rest
  }

  const classes = classNames(rootClassName || css.root, className, {
    [css.mobileMargins]: useMobileMargins
  })
  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={id}>
          {label}{' '}
          {writerIcon && (
            <Chip
              label=""
              icon={<FontAwesomeIcon icon={faRobot} size="sm" color="#000" />}
              borderColor={'#000'}
              color="#fffff"
            />
          )}
          {'  '}
          {showChip && required && <Chip label="Required" color="#FF7070" />}
        </label>
      ) : null}
      {subLabel ? (
        <label className={css.subLabel} htmlFor={id}>
          {subLabel}
        </label>
      ) : null}

      <select {...selectProps}>{children}</select>
      <ValidationError fieldMeta={meta} />
      {postLabel ? (
        <label className={css.postLabel} htmlFor={id}>
          {postLabel}
        </label>
      ) : null}
    </div>
  )
}

FieldSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  subLabel: null,
  postLabel: null,
  required: false,
  showChip: false,
  children: null,
  useMobileMargins: false
}

const { string, object, node, bool } = PropTypes

FieldSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,
  useMobileMargins: bool,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,
  subLabel: string,
  postLabel: string,
  required: bool,
  showChip: bool,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node
}

const FieldSelect = (props) => {
  return <Field component={FieldSelectComponent} {...props} />
}

export default FieldSelect
