import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      width: '100%',
      gap: '8px'
    },
    text: {
      fontSize: '14px',
      color: palette.text.disabled,
      padding: 0,
      letterSpacing: 0
    },
    locationUnavailableIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      height: '20px'
    },
    locationUnavailableIcon: {
      fontSize: '20px',
      color: palette.text.disabled
    }
  }
})

export const useLocationUnavailableBannerStyles = () => {
  const classes = useStyles()
  return { classes }
}
