import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      height: '42px',
      width: '313px',
      '&:hover': {
        borderColor: palette.blue['700']
      },
      '&:focus': {
        borderColor: palette.primary.main
      },
      [breakpoints.down('md')]: {
        width: '300px'
      }
    },
    placeholderText: {
      '&::placeholder': {
        fontSize: '16px'
      }
    },
    locationDotIcon: {
      width: '12px',
      height: '16px',
      color: palette.blue['800']
    },
    xMarkIcon: {
      width: '12px',
      height: '16px',
      color: palette.blue['800'],
      '&:hover': {
        cursor: 'pointer',
        color: palette.primary.main
      }
    }
  }
})

export const useLocationInputFieldStyles = ({ className, placeholderTextClassName }) => {
  const classes = useStyles()
  const joinedContainerClasses = classNames(classes.container, className)
  const joinedPlaceholderTextClasses = classNames(classes.inputText, placeholderTextClassName)

  return { joinedContainerClasses, joinedPlaceholderTextClasses, classes }
}
