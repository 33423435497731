import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      paddingTop: '6px',
      paddingBottom: '6px',
      borderRadius: '4px',
      backgroundColor: ({ isActive }) => (isActive ? palette.primary.light : palette.common.white),
      '&:hover': {
        backgroundColor: palette.grey['300']
      }
    },
    cityText: {
      fontSize: '16px',
      lineHeight: '19px',
      color: ({ isActive }) => (isActive ? palette.primary.main : palette.blue['800']),
      padding: 0,
      letterSpacing: 0
    },
    stateText: {
      fontSize: '12px',
      lineHeight: '20px',
      color: palette.blue['300'],
      padding: 0,
      letterSpacing: 0
    }
  }
})

export const useLocationListItemStyles = ({ isActive }) => {
  const classes = useStyles({ isActive })
  return { classes }
}
