import { useState } from 'react'
import { Typography, Box } from '@material-ui/core'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'

import { useUseMyLocationButtonStyles } from './hooks'

import { Button } from '../../../../../Button'
import { Icon } from '../../../../../Icon'

export const UseMyLocationButton = ({ onClick }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { classes } = useUseMyLocationButtonStyles()

  const clickHandler = async () => {
    try {
      setIsLoading(true)
      await onClick()
    } catch (e) {
      console.error('Error fetching user location:', e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button
      uiType="tertiary"
      className={classes.container}
      isLoading={isLoading}
      onClick={clickHandler}
    >
      <Box className={classes.titleContainer}>
        <Icon icon={faLocationArrow} className={classes.locationArrowIcon} />
        <Typography className={classes.title}>Use my current location</Typography>
      </Box>
    </Button>
  )
}
