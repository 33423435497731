import classNames from 'classnames'
import { bool, node, string } from 'prop-types'
import React from 'react'

import LayoutComposer from '../LayoutComposer'
import LayoutWrapperAccountSettingsSideNav from './LayoutWrapperAccountSettingsSideNav'

import { TopbarContainer } from '../../../containers/mui/TopbarContainer'
import { LegacyBox } from '../../mui/LegacyBox'
import css from './LayoutSideNavigation.module.css'

// Commonly used layout
const LayoutSideNavigation = (props) => {
  const {
    className,
    rootClassName,
    containerClassName,
    mainColumnClassName,
    sideNavClassName,
    children,
    topbar: topbarContent,
    footer: footerContent,
    sideNav: sideNavContent,
    useAccountSettingsNav,
    currentPage,
    ...rest
  } = props

  const classes = classNames(rootClassName || css.root, className)
  const containerClasses = containerClassName || css.container

  // TODO: since responsiveAreas are still experimental,
  //       we don't separate "aside" through layoutComposer
  const layoutAreas = `
    topbar
    main
    footer
  `

  return (
    <LayoutComposer areas={layoutAreas} className={classes} {...rest}>
      {(layoutProps) => {
        const { Topbar, Main, Footer } = layoutProps
        return (
          <>
            <TopbarContainer />
            <LegacyBox>
              <Main as="div" className={containerClasses}>
                <aside className={classNames(css.sideNav, sideNavClassName)}>
                  {useAccountSettingsNav ? (
                    <LayoutWrapperAccountSettingsSideNav currentPage={currentPage} />
                  ) : null}
                  {sideNavContent}
                </aside>
                <main className={classNames(css.main, mainColumnClassName)}>{children}</main>
              </Main>
            </LegacyBox>
          </>
        )
      }}
    </LayoutComposer>
  )
}

LayoutSideNavigation.displayName = 'LayoutSideNavigation'

LayoutSideNavigation.defaultProps = {
  className: null,
  rootClassName: null,
  sideNav: null,
  footer: null,
  useAccountSettingsNav: false,
  currentPage: null
}

LayoutSideNavigation.propTypes = {
  className: string,
  rootClassName: string,
  children: node.isRequired,
  topbar: node.isRequired,
  sideNav: node,
  footer: node,
  useAccountSettingsNav: bool,
  currentPage: string
}

export default LayoutSideNavigation
