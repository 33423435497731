import { createTheme } from '@material-ui/core'

import { lightPalette } from './palette'
import { breakpoints } from './breakpoints'
import { typography } from './typography'

export const theme = createTheme({
  palette: lightPalette,
  breakpoints,
  typography
})
