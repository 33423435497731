export const breakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 444,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536
  }
}
