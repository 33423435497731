import { ListItem, Typography } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { useEffect, useState } from 'react'

import { useDesktopConsultationListItemStyles } from './hooks'

import { highlightMatchingSubstring } from '../../../../../../../util/new/string'

export const DesktopConsultationListItem = ({
  consultation,
  consultationId,
  category,
  consultationInputValue,
  onClick
}) => {
  const [consultationValue, setConsultationValue] = useState(consultation)
  const { classes } = useDesktopConsultationListItemStyles({
    isActive: consultation === consultationInputValue
  })

  useEffect(() => {
    setConsultationValue(
      ReactHtmlParser(highlightMatchingSubstring(consultation, consultationInputValue))
    )
  }, [consultationInputValue])

  const clickHandler = () => {
    onClick({ id: consultationId, name: consultation, category })
  }

  return (
    <ListItem className={classes.container} onClick={clickHandler} button>
      <Typography className={classes.text}>{consultationValue}</Typography>
    </ListItem>
  )
}
