import { CircularProgress, Button as MaterialButton } from '@material-ui/core'

import { useButtonStyles } from './hooks'

export const Button = ({
  uiType = 'primary',
  size = 'large',
  href,
  target,
  isLoading,
  isDisabled,
  onClick,
  onMouseDown,
  className,
  children,
  style
}) => {
  const { variant, joinedClasses } = useButtonStyles({ uiType, isLoading, isDisabled, className })

  return (
    <MaterialButton
      variant={variant}
      size={size}
      href={href}
      target={target}
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={joinedClasses}
      disabled={isDisabled || isLoading}
      style={style}
    >
      {isLoading ? <CircularProgress size={16} /> : children}
    </MaterialButton>
  )
}
