import { ListItem, Typography } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch } from 'react-redux'

import { useLocationListItemStyles } from './hooks'

import { highlightMatchingSubstring } from '../../../../../../../util/new/string'

import { setLocationFilterOption } from '../../../../../../../containers/mui/SearchPage/duck'

export const LocationListItem = ({ city, state, coordinates, locationInputValue, onClick }) => {
  const isActive = locationInputValue === `${city}, ${state}`
  const { classes } = useLocationListItemStyles({ isActive })
  const dispatch = useDispatch()

  const clickHandler = () => {
    if (state) {
      onClick(`${city}, ${state}`)
      dispatch(
        setLocationFilterOption({
          name: `${city}, ${state}`,
          lat: coordinates.lat,
          lon: coordinates.lon
        })
      )
    } else {
      onClick(city)
      dispatch(
        setLocationFilterOption({
          name: city,
          lat: coordinates.lat,
          lon: coordinates.lon
        })
      )
    }
  }

  return (
    <ListItem className={classes.container} onClick={clickHandler} button>
      <Typography className={classes.cityText}>
        {ReactHtmlParser(highlightMatchingSubstring(city, locationInputValue))}
      </Typography>
      <Typography className={classes.stateText}>
        {ReactHtmlParser(highlightMatchingSubstring(state, locationInputValue))}
      </Typography>
    </ListItem>
  )
}
