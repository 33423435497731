import classNames from 'classnames'
import { node, string } from 'prop-types'
import React from 'react'

import LayoutComposer from '../LayoutComposer'

import { TopbarContainer } from '../../../containers/mui/TopbarContainer'
import { LegacyBox } from '../../mui/LegacyBox'
import css from './LayoutSingleColumn.module.css'

// Commonly used layout
const LayoutSingleColumn = (props) => {
  const {
    className,
    rootClassName,
    mainColumnClassName,
    children,
    topbar: topbarContent,
    footer: footerContent,
    ...rest
  } = props

  const classes = classNames(rootClassName || css.root, className)
  const layoutAreas = `
    topbar
    main
    footer
  `

  return (
    <LayoutComposer areas={layoutAreas} className={classes} {...rest}>
      {(layoutProps) => {
        const { Topbar, Main, Footer } = layoutProps
        return (
          <>
            <TopbarContainer />
            <LegacyBox>{children}</LegacyBox>
          </>
        )
      }}
    </LayoutComposer>
  )
}

LayoutSingleColumn.displayName = 'LayoutSingleColumn'

LayoutSingleColumn.defaultProps = {
  className: null,
  rootClassName: null,
  footer: null
}

LayoutSingleColumn.propTypes = {
  className: string,
  rootClassName: string,
  children: node.isRequired,
  topbar: node.isRequired,
  footer: node
}

export default LayoutSingleColumn
