import { ListItem, Typography } from '@material-ui/core'
import { useState, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { usePractitionerListItemStyles } from './hooks'

import { highlightMatchingSubstring } from '../../../../../../../util/new/string'
import NamedRedirect from '../../../../../../NamedRedirect/NamedRedirect'
import { createSlug } from '../../../../../../../util/urlHelpers'

export const PractitionerListItem = ({
  name,
  clinic,
  location,
  listingId,
  listingTitle,
  consultationInputValue,
  onClick
}) => {
  const mainText = clinic ? `${name} at ${clinic}` : name

  const [value, setValue] = useState(mainText)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const { classes } = usePractitionerListItemStyles()

  useEffect(() => {
    setValue(ReactHtmlParser(highlightMatchingSubstring(mainText, consultationInputValue)))
  }, [consultationInputValue])

  const clickHandler = () => {
    onClick()
    setShouldRedirect(true)
  }

  return (
    <>
      <ListItem onClick={clickHandler} className={classes.container} button>
        <Typography className={classes.practitionerAndClinicText}>{value}</Typography>
        <Typography className={classes.locationText}>{location}</Typography>
      </ListItem>
      {shouldRedirect && (
        <NamedRedirect
          name="ListingPage"
          params={{ id: listingId, slug: createSlug(listingTitle) }}
          push
        />
      )}
    </>
  )
}
