import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'

import { BottomMobileNavigation, MenuItem, UserMenuDropdown } from './components'
import { useTopbarStyles } from './hooks'

import { Button } from '../Button'

import LOGO from '../../../assets/stella-logo.svg'

export const Topbar = ({ authInProgress, currentUser, isAuthenticated, onLogout }) => {
  const [activeMenuItem, setActiveMenuItem] = useState('')
  const { classes } = useTopbarStyles()

  useEffect(() => {
    const path = window.location.pathname

    if (path.includes('/s')) {
      setActiveMenuItem('/s')
    } else if (path.includes('/online-concierge')) {
      setActiveMenuItem('/online-concierge')
    } else if (path.includes('/post-goal')) {
      setActiveMenuItem('/post-goal')
    } else if (path.includes('/listings')) {
      setActiveMenuItem('/listings')
    } else if (path.includes('/requests')) {
      setActiveMenuItem('/requests')
    }
  }, [])

  const renderMenuItems = () => {
    if (isAuthenticated && currentUser?.attributes?.profile?.privateData?.userType === 'provider') {
      return (
        <Box className={classes.menuItemContainer}>
          <MenuItem
            name="SearchPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/s'}
          >
            Find a practitioner
          </MenuItem>
          <MenuItem
            name="ManageListingsPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/listings'}
          >
            Dashboard
          </MenuItem>
          <MenuItem
            name="ConsultRequestsPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/requests'}
          >
            Requests
          </MenuItem>
        </Box>
      )
    }

    return (
      <Box className={classes.menuItemContainer}>
        <MenuItem name="SearchPage" className={classes.menuItem} isActive={activeMenuItem === '/s'}>
          Find a practitioner
        </MenuItem>
        <MenuItem
          name="PostConcernPage"
          className={classes.menuItem}
          isActive={activeMenuItem === '/post-goal'}
        >
          Post a goal
        </MenuItem>
        <MenuItem
          name="OnlineConciergePage"
          className={classes.menuItem}
          isActive={activeMenuItem === '/online-concierge'}
        >
          Online concierge
        </MenuItem>
      </Box>
    )
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          <a href="https://www.seestella.com.au/">
            <img src={LOGO} alt="stella-logo" className={classes.logo} />
          </a>
          {renderMenuItems()}
          {!authInProgress && isAuthenticated ? (
            <UserMenuDropdown currentUser={currentUser} onLogout={onLogout} />
          ) : (
            <Box className={classes.authButtonsContainer}>
              <Button uiType="tertiary" href="/login" size="large" className={classes.signInButton}>
                Sign in
              </Button>
              <Button uiType="primary" href="/signup" size="large" className={classes.signUpButton}>
                Sign up
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <BottomMobileNavigation isAuthenticated={isAuthenticated} currentUser={currentUser} />
    </>
  )
}
