import React from 'react'

const ICON_STROKE_COLOR = '#2A353D'

const icons = {
  contact: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 7C18.7745 7.16058 19.3588 7.42859 19.8284 7.87589C21 8.99181 21 10.7879 21 14.38C21 17.9721 21 19.7681 19.8284 20.8841C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8841C3 19.7681 3 17.9721 3 14.38C3 10.7879 3 8.99181 4.17157 7.87589C4.64118 7.42859 5.2255 7.16058 6 7"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.0253 2.00052L12 14M12.0253 2.00052C11.8627 1.99379 11.6991 2.05191 11.5533 2.17492C10.6469 2.94006 9 4.92886 9 4.92886M12.0253 2.00052C12.1711 2.00657 12.3162 2.06476 12.4468 2.17508C13.3531 2.94037 15 4.92886 15 4.92886"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  closeIcon: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.53846C7.32682 3.53846 3.53846 7.32682 3.53846 12C3.53846 16.6732 7.32682 20.4615 12 20.4615C16.6732 20.4615 20.4615 16.6732 20.4615 12C20.4615 7.32682 16.6732 3.53846 12 3.53846ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
        fill={ICON_STROKE_COLOR}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.40479 9.40479C9.70519 9.10439 10.1922 9.10439 10.4926 9.40479L12 10.9121L13.5074 9.40479C13.8078 9.10439 14.2948 9.10439 14.5952 9.40479C14.8956 9.70519 14.8956 10.1922 14.5952 10.4926L13.0879 12L14.5952 13.5074C14.8956 13.8078 14.8956 14.2948 14.5952 14.5952C14.2948 14.8956 13.8078 14.8956 13.5074 14.5952L12 13.0879L10.4926 14.5952C10.1922 14.8956 9.70519 14.8956 9.40479 14.5952C9.10439 14.2948 9.10439 13.8078 9.40479 13.5074L10.9121 12L9.40479 10.4926C9.10439 10.1922 9.10439 9.70519 9.40479 9.40479Z"
        fill={ICON_STROKE_COLOR}
      />
    </svg>
  ),
  instagramIcon: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z"
        stroke="#2A353D"
        strokeWidth="1.5"
      />
      <path
        d="M17.5078 6.5L17.4988 6.5"
        stroke="#2A353D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  writeReviewIcon: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12903 16.1026C8.12903 16.5274 8.47565 16.8718 8.90323 16.8718H15.0968C15.5243 16.8718 15.871 16.5274 15.871 16.1026C15.871 15.6777 15.5243 15.3333 15.0968 15.3333H8.90323C8.47565 15.3333 8.12903 15.6777 8.12903 16.1026Z"
        fill="#030D45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12903 12C8.12903 12.4248 8.47565 12.7692 8.90323 12.7692H12C12.4276 12.7692 12.7742 12.4248 12.7742 12C12.7742 11.5752 12.4276 11.2308 12 11.2308H8.90323C8.47565 11.2308 8.12903 11.5752 8.12903 12Z"
        fill="#030D45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.96832 2.86688C9.28943 2.34443 9.85266 2 10.4879 2H13.5121C14.1473 2 14.7106 2.34443 15.0317 2.86688L15.598 3.78825C16.1575 3.83176 16.6881 3.87857 17.1512 3.92214C18.4998 4.04902 19.5504 5.11757 19.6563 6.45519C19.7985 8.25286 20 11.1773 20 13.3444C20 15.3896 19.8208 17.7117 19.6808 19.216C19.5583 20.5333 18.5145 21.5672 17.1922 21.6922C15.7554 21.8279 13.6198 22 12 22C10.3802 22 8.24457 21.8279 6.80778 21.6922C5.48548 21.5672 4.44171 20.5333 4.31916 19.216C4.1792 17.7117 4 15.3896 4 13.3444C4 11.1773 4.20149 8.25286 4.34373 6.45519C4.44956 5.11757 5.50024 4.04902 6.84879 3.92214C7.31193 3.87857 7.8425 3.83176 8.402 3.78825L8.96832 2.86688ZM8.02441 5.36223C7.65829 5.3928 7.31087 5.42401 6.99475 5.45375C6.39941 5.50977 5.93455 5.97928 5.88735 6.57577C5.74518 8.37253 5.54839 11.2436 5.54839 13.3444C5.54839 15.3175 5.72226 17.5834 5.86097 19.0744C5.91474 19.6523 6.3697 20.1054 6.95435 20.1606C8.38773 20.296 10.4598 20.4615 12 20.4615C13.5402 20.4615 15.6123 20.296 17.0456 20.1606C17.6303 20.1054 18.0853 19.6523 18.139 19.0744C18.2777 17.5834 18.4516 15.3175 18.4516 13.3444C18.4516 11.2436 18.2548 8.37253 18.1126 6.57577C18.0655 5.97928 17.6006 5.50977 17.0052 5.45376C16.6891 5.42401 16.3417 5.3928 15.9756 5.36223C15.9352 5.5803 15.8588 5.7884 15.7521 5.97609C15.4604 6.48894 14.9092 6.89732 14.2002 6.89732H9.79976C9.09084 6.89732 8.53958 6.48894 8.24791 5.97609C8.14117 5.7884 8.06476 5.5803 8.02441 5.36223ZM10.4879 3.53846C10.4329 3.53846 10.3515 3.56821 10.2898 3.66872L9.60159 4.78834C9.59146 4.80483 9.58285 4.82123 9.57557 4.83747C9.52007 4.96132 9.53158 5.10602 9.59596 5.21923C9.6574 5.32725 9.73397 5.35886 9.79976 5.35886H14.2002C14.266 5.35886 14.3426 5.32725 14.404 5.21923C14.4684 5.10602 14.4799 4.96132 14.4244 4.83747C14.4171 4.82123 14.4085 4.80483 14.3984 4.78834L13.7102 3.66872C13.6485 3.56821 13.5671 3.53846 13.5121 3.53846H10.4879Z"
        fill="#030D45"
      />
    </svg>
  )
}

const Icons = ({ name }) => {
  return icons[name]
}

export default Icons
