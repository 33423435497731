import { faRobot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import loadable from '@loadable/component'
import { func, object, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'react-final-form'
import { ValidationError } from '../../components'
import Chip from '../Chip/Chip'

// LocationAutocompleteInputImpl is a big component that includes code for both Mapbox and Google Maps
// It is loaded dynamically - i.e. it is splitted to its own code chunk.
const LocationAutocompleteInputImpl = loadable(
  () =>
    import(
      /* webpackChunkName: "LocationAutocompleteInputImpl" */ './LocationAutocompleteInputImpl'
    )
)

class LocationAutocompleteInputComponent extends Component {
  render() {
    /* eslint-disable no-unused-vars */
    const { rootClassName, labelClassName, subLabelClassName, hideErrorMessage, ...restProps } =
      this.props
    const { input, writerIcon, label, subLabel, meta, valueFromForm, ...otherProps } = restProps
    /* eslint-enable no-unused-vars */

    const value = typeof valueFromForm !== 'undefined' ? valueFromForm : input.value
    const locationAutocompleteProps = {
      label,
      meta,
      ...otherProps,
      input: { ...input, value }
    }
    const labelInfo = label ? (
      <label className={labelClassName} htmlFor={input.name}>
        {label}{' '}
        {writerIcon && (
          <Chip
            label=""
            icon={<FontAwesomeIcon icon={faRobot} size="sm" color="#000" />}
            borderColor={'#000'}
            color="#fffff"
          />
        )}
        {'  '} <Chip label="Required" color="#FF7070" />
      </label>
    ) : null

    const subLabelInfo = subLabel ? <label className={subLabelClassName}>{subLabel}</label> : null

    return (
      <div className={rootClassName}>
        {labelInfo}
        {subLabelInfo}
        <LocationAutocompleteInputImpl {...locationAutocompleteProps} />
        {hideErrorMessage ? null : <ValidationError fieldMeta={meta} />}
      </div>
    )
  }
}

LocationAutocompleteInputComponent.defaultProps = {
  rootClassName: null,
  labelClassName: null,
  subLabelClassName: null,
  type: null,
  label: null,
  subLabel: null
}

LocationAutocompleteInputComponent.propTypes = {
  rootClassName: string,
  labelClassName: string,
  subLabelClassName: string,
  input: shape({
    onChange: func.isRequired,
    name: string.isRequired
  }).isRequired,
  label: string,
  subLabel: string,
  meta: object.isRequired
}

export default LocationAutocompleteInputImpl

export const FieldLocationAutocompleteInput = (props) => {
  return <Field component={LocationAutocompleteInputComponent} {...props} />
}
