export const getFullUsername = (currentUser) => {
  if (currentUser) {
    const { attributes } = currentUser
    const { profile } = attributes
    const { firstName, lastName } = profile

    return `${firstName} ${lastName}`
  }

  return undefined
}

export const getProfileImageUrl = (currentUser) => {
  if (currentUser) {
    return currentUser.profileImage?.attributes.variants['square-small'].url
  }

  return undefined
}

export const getEmail = (currentUser) => {
  if (currentUser) {
    return currentUser.attributes.email
  }

  return undefined
}

export const getUserType = (currentUser) => {
  if (currentUser) {
    return currentUser.attributes.profile?.privateData?.userType
  }

  return undefined
}

export const getIsUserProvider = (currentUser) => {
  return getUserType(currentUser) === 'provider'
}
