import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      borderRadius: '4px'
    },
    practitionerAndClinicText: {
      fontSize: '16px',
      lineHeight: '20px',
      color: palette.blue['800'],
      padding: 0,
      letterSpacing: 0
    },
    locationText: {
      fontSize: '12px',
      lineHeight: '20px',
      color: palette.blue['300'],
      padding: 0,
      letterSpacing: 0
    }
  }
})

export const usePractitionerListItemStyles = () => {
  const classes = useStyles()
  return { classes }
}
