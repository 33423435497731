import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import { theme } from '../../../../theme'

const useStyles = makeStyles(() => {
  const { palette } = theme

  const defaultBorderColors = {
    credit: palette.chip.turquoise,
    consultation: palette.chip.blue,
    treatment: palette.chip.grey,
    primary: palette.primary.main,
    disabled: palette.text.disabled,
    support: palette.chip.support
  }

  const defaultLabelColors = {
    credit: palette.chip.turquoise,
    consultation: palette.chip.blue,
    treatment: palette.blue['800'],
    primary: palette.primary.main,
    disabled: palette.blue['800'],
    support: palette.chip.support
  }

  return {
    container: {
      fontFamily: 'Inter',
      fontWeight: 400,
      color: ({ type }) => defaultLabelColors[type],
      borderColor: ({ type }) => defaultBorderColors[type],
      '&:hover': {
        cursor: ({ isClickable }) => (isClickable ? 'pointer' : 'cursor')
      }
    }
  }
})

export const useChipStyles = ({ className, type, isClickable }) => {
  const classes = useStyles({ type, isClickable })
  const joinedClasses = classNames(classes.container, className)

  return { joinedClasses }
}
