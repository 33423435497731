import { mapListingsToCommonStructure } from '../../../components/mui/utils/practitionerListing'
import { consultationIndex } from '../../../lib/algolia'
import { fetchListing, fetchPrivateListing, fetchReviewsOfListing } from '../../../util/api'
import { LISTING_STATE_CLOSED } from '../../../util/types'
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT
} from '../../../util/urlHelpers'
import { mapReviewsToCommonFormat } from './utils/reviews'

// ================ ACTION TYPES ================ //

export const FETCH_LISTING_REQUEST = 'app/NewListingPage/FETCH_LISTING_REQUEST'
export const FETCH_LISTING_SUCCESS = 'app/NewListingPage/FETCH_LISTING_SUCCESS'
export const FETCH_LISTING_ERROR = 'app/NewListingPage/FETCH_LISTING_ERROR'

export const FETCH_CONSULTATIONS_REQUEST = 'app/NewListingPage/FETCH_CONSULTATIONS_REQUEST'
export const FETCH_CONSULTATIONS_SUCCESS = 'app/NewListingPage/FETCH_CONSULTATIONS_SUCCESS'
export const FETCH_CONSULTATIONS_ERROR = 'app/NewListingPage/FETCH_CONSULTATIONS_ERROR'

export const FETCH_LISTING_REVIEWS_REQUEST = 'app/NewListingPage/FETCH_LISTING_REVIEWS_REQUEST'
export const FETCH_LISTING_REVIEWS_SUCCESS = 'app/NewListingPage/FETCH_LISTING_REVIEWS_SUCCESS'
export const FETCH_LISTING_REVIEWS_ERROR = 'app/NewListingPage/FETCH_LISTING_REVIEWS_ERROR'

export const FETCH_LISTING_AND_REVIEWS_REQUEST =
  'app/NewListingPage/FETCH_LISTING_AND_REVIEWS_REQUEST'
export const FETCH_LISTING_AND_REVIEWS_SUCCESS =
  'app/NewListingPage/FETCH_LISTING_AND_REVIEWS_SUCCESS'
export const FETCH_LISTING_AND_REVIEWS_ERROR = 'app/NewListingPage/FETCH_LISTING_AND_REVIEWS_ERROR'

export const ADD_LISTING_REVIEW_REQUEST = 'app/NewListingPage/ADD_LISTING_REVIEW_REQUEST'
export const ADD_LISTING_REVIEW_SUCCESS = 'app/NewListingPage/ADD_LISTING_REVIEW_SUCCESS'
export const ADD_LISTING_REVIEW_ERROR = 'app/NewListingPage/ADD_LISTING_REVIEW_ERROR'

export const ADD_LISTING_REVIEW_RESPONSE_REQUEST =
  'app/NewListingPage/ADD_LISTING_REVIEW_RESPONSE_REQUEST'
export const ADD_LISTING_REVIEW_RESPONSE_SUCCESS =
  'app/NewListingPage/ADD_LISTING_REVIEW_RESPONSE_SUCCESS'
export const ADD_LISTING_REVIEW_RESPONSE_ERROR =
  'app/NewListingPage/ADD_LISTING_REVIEW_RESPONSE_ERROR'

export const CLEAR_LISTING = 'app/NewListingPage/CLEAR_LISTING'

// ================ REDUCER ================ //

const initialState = {
  listing: undefined,
  reviews: [],
  isFetchReviewsInProgress: false,
  consultations: [],
  isFetchConsultationsInProgress: false,
  isAddReviewInProgress: false,
  isAddReviewResponseInProgress: false,
  isLoading: false,
  fetchReviewsError: undefined,
  fetchConsultationsError: undefined,
  addReviewError: undefined,
  addReviewResponseError: undefined,
  error: undefined,
  feedback: undefined
}

const practitionerListingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_LISTING_REQUEST:
      return {
        ...state,
        isLoading: true,
        listing: undefined,
        error: undefined
      }
    case FETCH_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listing: payload,
        error: undefined
      }
    case FETCH_LISTING_ERROR:
      return {
        ...state,
        isLoading: false,
        listing: undefined,
        error: payload
      }
    case FETCH_CONSULTATIONS_REQUEST:
      return {
        ...state,
        isFetchConsultationsInProgress: true,
        consultations: undefined,
        fetchConsultationsError: undefined
      }
    case FETCH_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        isFetchConsultationsInProgress: false,
        consultations: payload,
        fetchConsultationsError: undefined
      }
    case FETCH_CONSULTATIONS_ERROR:
      return {
        ...state,
        isFetchConsultationsInProgress: false,
        consultations: undefined,
        fetchConsultationsError: payload
      }
    case FETCH_LISTING_REVIEWS_REQUEST:
      return {
        ...state,
        isFetchReviewsInProgress: true,
        // reviews: [],
        // feedback: undefined,
        fetchReviewsError: undefined
      }
    case FETCH_LISTING_REVIEWS_SUCCESS:
      return {
        ...state,
        isFetchReviewsInProgress: false,
        reviews: payload.reviews,
        feedback: payload.feedback,
        fetchReviewsError: undefined
      }
    case FETCH_LISTING_REVIEWS_ERROR:
      return {
        ...state,
        isFetchReviewsInProgress: false,
        reviews: [],
        feedback: undefined,
        fetchReviewsError: payload
      }
    case FETCH_LISTING_AND_REVIEWS_REQUEST:
      return {
        ...state,
        isFetchReviewsInProgress: true,
        isLoading: true,
        reviews: [],
        listing: undefined,
        feedback: undefined,
        error: undefined,
        fetchReviewsError: undefined
      }
    case FETCH_LISTING_AND_REVIEWS_SUCCESS:
      return {
        ...state,
        isFetchReviewsInProgress: false,
        isLoading: false,
        reviews: payload.reviews,
        listing: payload.listing,
        feedback: payload.feedback,
        error: undefined,
        fetchReviewsError: undefined
      }
    case FETCH_LISTING_AND_REVIEWS_ERROR:
      return {
        ...state,
        isFetchReviewsInProgress: false,
        isLoading: false,
        reviews: [],
        listing: undefined,
        feedback: undefined,
        error: payload,
        fetchReviewsError: payload
      }
    case ADD_LISTING_REVIEW_REQUEST:
      return {
        ...state,
        isAddReviewInProgress: true,
        addReviewError: undefined
      }
    case ADD_LISTING_REVIEW_SUCCESS:
      return {
        ...state,
        isAddReviewInProgress: false,
        addReviewError: undefined
      }
    case ADD_LISTING_REVIEW_ERROR:
      return {
        ...state,
        isAddReviewInProgress: false,
        addReviewError: payload
      }
    case ADD_LISTING_REVIEW_RESPONSE_REQUEST:
      return {
        ...state,
        isAddReviewResponseInProgress: true,
        addReviewResponseError: undefined
      }
    case ADD_LISTING_REVIEW_RESPONSE_SUCCESS:
      return {
        ...state,
        isAddReviewResponseInProgress: false,
        addReviewResponseError: undefined
      }
    case ADD_LISTING_REVIEW_RESPONSE_ERROR:
      return {
        ...state,
        isAddReviewResponseInProgress: false,
        addReviewResponseError: payload
      }
    case CLEAR_LISTING: {
      return {
        ...state,
        listing: undefined
      }
    }
    default:
      return state
  }
}

export default practitionerListingPageReducer

// ================ ACTION CREATORS ================ //

export const fetchListingRequest = () => ({
  type: FETCH_LISTING_REQUEST
})

export const fetchListingSuccess = (listing) => ({
  type: FETCH_LISTING_SUCCESS,
  payload: listing
})

export const fetchListingError = (error) => ({
  type: FETCH_LISTING_ERROR,
  payload: error
})

export const fetchConsultationsRequest = () => ({
  type: FETCH_CONSULTATIONS_REQUEST
})

export const fetchConsultationsSuccess = (consultations) => ({
  type: FETCH_CONSULTATIONS_SUCCESS,
  payload: consultations
})

export const fetchConsultationsError = (error) => ({
  type: FETCH_CONSULTATIONS_ERROR,
  payload: error
})

export const fetchListingReviewsRequest = () => ({
  type: FETCH_LISTING_REVIEWS_REQUEST
})

export const fetchListingReviewsSuccess = (reviews) => ({
  type: FETCH_LISTING_REVIEWS_SUCCESS,
  payload: reviews
})

export const fetchListingReviewsError = (error) => ({
  type: FETCH_LISTING_REVIEWS_ERROR,
  payload: error
})

export const fetchListingAndReviewsRequest = () => ({
  type: FETCH_LISTING_AND_REVIEWS_REQUEST
})

export const fetchListingAndReviewsSuccess = (listingAndReviews) => ({
  type: FETCH_LISTING_AND_REVIEWS_SUCCESS,
  payload: listingAndReviews
})

export const fetchListingAndReviewsError = (error) => ({
  type: FETCH_LISTING_AND_REVIEWS_ERROR,
  payload: error
})

export const addListingReviewRequest = (listingId, review) => ({
  type: ADD_LISTING_REVIEW_REQUEST,
  payload: { listingId, review }
})

export const addListingReviewSuccess = () => ({
  type: ADD_LISTING_REVIEW_SUCCESS
})

export const addListingReviewError = (error) => ({
  type: ADD_LISTING_REVIEW_ERROR,
  payload: error
})

export const addListingReviewResponseRequest = (comment) => ({
  type: ADD_LISTING_REVIEW_RESPONSE_REQUEST,
  payload: { comment }
})

export const addListingReviewResponseSuccess = () => ({
  type: ADD_LISTING_REVIEW_RESPONSE_SUCCESS
})

export const addListingReviewResponseError = (error) => ({
  type: ADD_LISTING_REVIEW_RESPONSE_ERROR,
  payload: error
})

export const clearListing = () => ({
  type: CLEAR_LISTING
})

// ================ SELECTORS ================ //

const selectPractitionerListingPage = (state) => state.NewListingPage

export const selectPractitionerListing = (state) => selectPractitionerListingPage(state).listing
export const selectIsLoading = (state) => selectPractitionerListingPage(state).isLoading
export const selectError = (state) => selectPractitionerListingPage(state).error

export const selectConsultations = (state) => selectPractitionerListingPage(state).consultations
export const selectConsultationsLoading = (state) =>
  selectPractitionerListingPage(state).isFetchConsultationsInProgress
export const selectConsultationsError = (state) =>
  selectPractitionerListingPage(state).fetchConsultationsError

export const selectFeedback = (state) => selectPractitionerListingPage(state).feedback

export const selectReviews = (state) => selectPractitionerListingPage(state).reviews
export const selectIsAddReviewResponseInProgress = (state) =>
  selectPractitionerListingPage(state).isAddReviewResponseInProgress
export const selectIsFetchReviewsInProgress = (state) =>
  selectPractitionerListingPage(state).isFetchReviewsInProgress
export const selectIsAddReviewInProgress = (state) =>
  selectPractitionerListingPage(state).isAddReviewInProgress
export const selectAddReviewError = (state) => selectPractitionerListingPage(state).addReviewError
export const selectAddReviewResponseError = (state) =>
  selectPractitionerListingPage(state).addReviewResponseError
export const selectFetchReviewsError = (state) =>
  selectPractitionerListingPage(state).fetchReviewsError

// ================ THUNKS ================ //

export const fetchPractitionerListingReviews = (id, userId) => async (dispatch) => {
  dispatch(fetchListingReviewsRequest())

  try {
    const response = await fetchReviewsOfListing({ listingId: id, userId })
    const formattedReviews = mapReviewsToCommonFormat(response.reviews)
    const feedback = response.feedback
    dispatch(fetchListingReviewsSuccess({ reviews: formattedReviews, feedback }))
  } catch (error) {
    console.error('Failed to fetch practitioner listing reviews', err)
    dispatch(fetchListingReviewsError(error))
  }
}

export const fetchPractitionerListing = (listingId, userId, variant) => async (dispatch) => {
  dispatch(fetchListingAndReviewsRequest())

  try {
    let response
    const ownListingVariants = [
      LISTING_PAGE_DRAFT_VARIANT,
      LISTING_PAGE_PENDING_APPROVAL_VARIANT,
      LISTING_STATE_CLOSED
    ]
    if (variant && ownListingVariants.includes(variant)) {
      response = await fetchPrivateListing(
        { listingId, userId, reviews: true, feedback: false },
        {}
      )
    } else {
      response = await fetchListing({ listingId, userId, reviews: true }, {})
    }

    if (!response) {
      throw new Error('No response from API')
    }

    const listing = mapListingsToCommonStructure([response.response.listing])[0]
    const reviews = response.response.reviews
    const feedback = response.response.feedback

    dispatch(
      fetchListingAndReviewsSuccess({
        listing,
        feedback,
        reviews: mapReviewsToCommonFormat(reviews)
      })
    )
  } catch (err) {
    console.log('Error fetching listing from API: ', err)
    dispatch(fetchListingAndReviewsError(err))
  }
}

export const fetchAllConsultations = async (dispatch) => {
  try {
    dispatch(fetchConsultationsRequest())
    const consultations = await consultationIndex.search('')
    dispatch(fetchConsultationsSuccess(consultations.hits))
  } catch (err) {
    console.error('Failed to fetch consultations from Algolia', err)
    dispatch(fetchConsultationsSuccess([]))
  }
}
