import classNames from 'classnames'
import { bool, func, number, object, string } from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink
} from '../../../components'
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration'
import { FormattedMessage, intlShape } from '../../../util/reactIntl'
import { propTypes } from '../../../util/types'

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm'

import css from './TopbarDesktop.module.css'

const TopbarDesktop = (props) => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    privateMode
  } = props
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const marketplaceName = appConfig.marketplaceName
  const authenticatedOnClientSide = mounted && isAuthenticated
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted

  const classes = classNames(rootClassName || css.root, className)

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  )

  const isProvider = currentUser?.attributes.profile.privateData?.userType === 'provider' || false

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage)
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null
  }

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {isProvider ? (
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
        ) : (
          <MenuItem key="ManageListingsPage"></MenuItem>
        )}
        {isProvider ? (
          <MenuItem key="ConsultRequestsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ConsultRequestsPage'))}
              name="ConsultRequestsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.consultRequestLink" />
            </NamedLink>
          </MenuItem>
        ) : (
          <MenuItem key="ConsultRequestsPage"></MenuItem>
        )}

        {isProvider ? (
          <MenuItem key="SearchPractitioners-Practitioner">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('SearchPage'))}
              name="SearchPage"
            >
              Search Practitioners
            </NamedLink>
          </MenuItem>
        ) : (
          <MenuItem key="SearchPractitioners-Practitioner"></MenuItem>
        )}

        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>

        {isProvider ? (
          <MenuItem key="LearnAboutStella-Practitioner">
            <span className={css.menuDivider}></span>
            <a
              href="https://seestella.com.au"
              target="_blank"
              className={classNames(css.profileSettingsLink)}
              rel="noreferrer"
            >
              <span className={css.menuItemBorder} />
              Learn about Stella
            </a>
          </MenuItem>
        ) : (
          <MenuItem key="LearnAboutStella-Practitioner"></MenuItem>
        )}

        {isProvider ? (
          <MenuItem key="HowItWorks-Practitioner">
            <a
              href="https://www.seestella.com.au/#howItWorks"
              target="_blank"
              className={classNames(css.profileSettingsLink)}
              rel="noreferrer"
            >
              <span className={css.menuItemBorder} />
              How it works
            </a>
          </MenuItem>
        ) : (
          <MenuItem key="HowItWorks-Practitioner"></MenuItem>
        )}
        {isProvider ? (
          <MenuItem key="ForPractitioners">
            <a
              href="https://seestella.com.au/practitioners"
              target="_blank"
              className={classNames(css.profileSettingsLink)}
              rel="noreferrer"
            >
              <span className={css.menuItemBorder} />
              For practitioners
            </a>
          </MenuItem>
        ) : (
          <MenuItem key="ForPractitioners"></MenuItem>
        )}

        {!isProvider ? (
          <MenuItem key="FindPractitioner">
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('SearchPage'))}
              name="SearchPage"
            >
              <span className={css.menuItemBorder} />
              Find a Practitioner
            </NamedLink>
          </MenuItem>
        ) : (
          <MenuItem key="FindPractitioner"></MenuItem>
        )}

        {!isProvider ? (
          <MenuItem key="LearnAboutStella">
            <span className={css.menuDivider}></span>
            <a
              href="https://seestella.com.au"
              target="_blank"
              className={classNames(css.profileSettingsLink)}
              rel="noreferrer"
            >
              <span className={css.menuItemBorder} />
              Learn about Stella
            </a>
          </MenuItem>
        ) : (
          <MenuItem key="LearnAboutStella"></MenuItem>
        )}

        {!isProvider ? (
          <MenuItem key="HowItWorks">
            <a
              href="https://www.seestella.com.au/#howItWorks"
              target="_blank"
              className={classNames(css.profileSettingsLink)}
              rel="noreferrer"
            >
              <span className={css.menuItemBorder} />
              How it works
            </a>
          </MenuItem>
        ) : (
          <MenuItem key="HowItWorks"></MenuItem>
        )}

        <MenuItem key="FAQs">
          <a
            href="https://www.seestella.com.au/faqs"
            target="_blank"
            className={classNames(css.profileSettingsLink)}
            rel="noreferrer"
          >
            <span className={css.menuItemBorder} />
            FAQs
          </a>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  )

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  )

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        privateMode={privateMode}
      />

      {privateMode ? (
        <></>
      ) : (
        <>
          {search}

          {/* <NamedLink className={css.createListingLink} name="NewListingPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink> */}

          {/* {currentUserHasListings ? (
            <></>
          ) : (
            <>
              {authenticatedOnClientSide ? (
                <>
                  <NamedLink
                    className={css.createListingLink}
                    name="NewListingPage"
                  >
                    <span className={css.createListing}>
                      <FormattedMessage id="TopbarDesktop.createListing" />
                    </span>
                  </NamedLink>
                </>
              ) : (
                <>
                  <NamedLink
                    className={css.createListingLink}
                    name="NewListingPage"
                  >
                    <span className={css.createListing}>
                      <FormattedMessage id="TopbarDesktop.nurseSignUp" />
                    </span>
                  </NamedLink>
                </>
              )}
            </>
          )} */}

          {/* {inboxLink} */}
          {profileMenu}
          {signupLink}
          {loginLink}
        </>
      )}
    </nav>
  )
}

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null
}

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object
}

export default TopbarDesktop
