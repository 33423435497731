import loadable from '@loadable/component'
import classNames from 'classnames'
import omit from 'lodash/omit'
import { array, bool, func, node, number, object, oneOfType, shape, string } from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { formatMoney } from '../../util/currency'
import { userDisplayNameAsString } from '../../util/data'
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl'
import {
  LINE_ITEM_DAY,
  LINE_ITEM_HOUR,
  LINE_ITEM_ITEM,
  LINE_ITEM_NIGHT,
  LISTING_STATE_CLOSED,
  propTypes
} from '../../util/types'
import { parse, stringify } from '../../util/urlHelpers'

import {
  AvatarMedium,
  Button,
  H1,
  H2,
  ModalInMobile,
  PrimaryButton,
  SecondaryButton
} from '../../components'
import {
  getSupportedProcessesInfo,
  isBookingProcess,
  resolveLatestProcessName
} from '../../transactions/transaction'

import { AnalyticsEvent, trackEvent } from '../../analytics/handlers'
import css from './OrderPanel.module.css'

const BookingTimeForm = loadable(
  () => import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
)
const BookingDatesForm = loadable(
  () => import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
)
const ProductOrderForm = loadable(
  () => import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
)

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023
const TODAY = new Date()

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price)
    return { formattedPrice, priceTitle: formattedPrice }
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`
    }
  }
  return {}
}

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0)
  } else {
    const { pathname, search, state } = location
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`
    history.push(`${pathname}${searchString}`, state)
  }
}

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location
  const searchParams = omit(parse(search), 'orderOpen')
  const searchString = `?${stringify(searchParams)}`
  history.push(`${pathname}${searchString}`, state)
}

const couponCode = (location) => {
  const couponCode = new URLSearchParams(location.search).get('couponCode')
  // sessionStorage.setItem('couponCode', couponCode)
  // console.log('couponCode', couponCode)
  return couponCode
}

const dateFormattingOptions = {
  month: 'short',
  day: 'numeric',
  weekday: 'short'
}

const OrderPanel = (props) => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    renderCopyToClipboardButton,
    isListingPage,
    onOpenBookingModal,
    bookNowInProgress,
    bookNowReady,
    onOpenRequestToModelModal,
    renderAverageReviews,
    specialOffers,
    isRenderSpecialOffers,
    buttonTitle
  } = props

  const publicData = listing?.attributes?.publicData
  const transactionProcessAlias = publicData?.transactionProcessAlias || ''
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0])
  const unitType = publicData?.unitType
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`

  const price = listing?.attributes?.price

  const showPriceMissing = !price
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    )
  }
  const showInvalidCurrency = price?.currency !== marketplaceCurrency
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    )
  }

  const timeZone = listing?.attributes?.availabilityPlan?.timezone
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED

  const isBooking = isBookingProcess(processName)
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType)
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType)
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity
  const isOutOfStock = lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const shouldHaveProductOrder = !isBooking && [LINE_ITEM_ITEM].includes(lineItemUnitType)
  const showProductOrderForm = shouldHaveProductOrder && typeof currentStock === 'number'

  const supportedProcessesInfo = getSupportedProcessesInfo()
  const isKnownProcess = supportedProcessesInfo.map((info) => info.name).includes(processName)

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {}

  const showClosedListingHelpText = listing.id && isClosed
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl)
  const isOrderOpen = !!parse(location.search).orderOpen

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null

  const authorDisplayName = userDisplayNameAsString(author, '')

  const classes = classNames(rootClassName || css.root, className)
  const titleClasses = classNames(titleClassName || css.orderTitle)

  const renderRequestToModelMaybe =
    publicData?.model_offers_enabled && publicData?.model_offers_enabled === 'yes' ? (
      <div style={{ marginTop: '50px' }}>
        <SecondaryButton type="button" onClick={onOpenRequestToModelModal}>
          <FormattedMessage id="BookingTimeForm.requestToModel" />
        </SecondaryButton>
      </div>
    ) : null

  // 03/14/2024 - Added logic to only show the View Clinic button.
  // Visit Website button will no longer be shown.
  const renderWebsiteButtonMaybe =
    publicData?.websiteURL && publicData?.associatedClinic ? (
      <Button
        onClick={() => {
          if (publicData?.associatedClinic) {
            trackEvent(AnalyticsEvent.visitClinicTapped, { listingId: listing.id.uuid })
            window.location.href = publicData?.websiteURL
          } else {
            trackEvent(AnalyticsEvent.visitWebsiteTapped, { listingId: listing.id.uuid })
            window.open(publicData?.websiteURL, '_blank', 'noopener,noreferrer')
          }
        }}
        className="mt-3"
      >
        <FormattedMessage
          id={
            publicData?.associatedClinic
              ? 'BookingTimeForm.viewClinic'
              : 'BookingTimeForm.visitWebsite'
          }
        />
      </Button>
    ) : null

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div>

        <div className={css.orderHeading}>
          {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
          {renderCopyToClipboardButton}
        </div>
        {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        {renderAverageReviews}
        {price ? (
          <>
            {/* <div className={css.priceContainer}>
              <p className={css.price}>{formatMoney(intl, price)}</p>
              <div className={css.perUnit}>
              <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
            </div>
            </div> */}
          </>
        ) : null}

        <div className={css.author}>
          <div className={css.authorInfo}>
            <AvatarMedium user={author} className={css.providerAvatar} disableProfileLink={true} />
            <span className={css.providerNameLinked}>
              <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
            </span>
            <span className={css.providerNamePlain}>
              <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
            </span>
          </div>
          {/* <div>
            {publicData?.instagramLink ? (
              <span className={css.instagramLink}>
                <ExternalLink
                  target="_blank"
                  href={publicData?.instagramLink}
                  onClick={() =>
                    trackEvent(AnalyticsEvent.instagramTapped, { listingId: listing.id.uuid })
                  }
                >
                  {' '}
                  <Icons name="instagramIcon" />
                </ExternalLink>
              </span>
            ) : null}
          </div> */}
        </div>
        {isListingPage ? (
          <div>
            <div className={css.externalBookingLink}>
              <Button
                type="button"
                inProgress={bookNowInProgress}
                ready={bookNowReady}
                onClick={onOpenBookingModal}
              >
                {buttonTitle ?? <FormattedMessage id="BookingTimeForm.requestToBook" />}
              </Button>
              {/* {renderWebsiteButtonMaybe} */}
              {/* {renderRequestToModelMaybe} */}
            </div>
          </div>
        ) : null}

        {/* {couponCode(location) !== null ? (
          <div className={css.author}>
            <FormattedMessage id="OrderPanel.couponToBeAppliedOnCheckout" />
          </div>
        ) : (
          <></>
        )} */}

        {/* {showPriceMissing ? ( */}
        {/* {showPriceMissing ? (
          <PriceMissing />
        ) : showInvalidCurrency ? (
          <InvalidCurrency />
        ) : showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            couponCode={couponCode(location)}
          />
        ) : showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : showProductOrderForm ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            currentStock={currentStock}
            pickupEnabled={pickupEnabled}
            shippingEnabled={shippingEnabled}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null} */}
      </ModalInMobile>

      <div className={css.openOrderForm}>
        {couponCode(location) ? (
          <div style={{ textAlign: 'center' }}>
            <FormattedMessage id="OrderPanel.couponToBeAppliedOnCheckout" />
          </div>
        ) : null}

        <div className={css.priceContainerInCTA}>
          {/* <div
            className={css.priceValue}
            title={priceTitle}
            style={{ marginBottom: 10, marginTop: 10, fontSize: 22 }}
          >
            {formattedPrice}
          </div> */}
          {/* <div className={css.perUnitInCTA}>
            <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
          </div> */}
        </div>

        <div className={css.externalBookingLink}>
          {isClosed ? (
            <div className={css.closedListingButton}>
              <FormattedMessage id="OrderPanel.closedListingButtonText" />
            </div>
          ) : (
            <PrimaryButton
              onClick={onOpenBookingModal}
              inProgress={bookNowInProgress}
              ready={bookNowReady}
            >
              {isBooking ? (
                <>{buttonTitle ?? <FormattedMessage id="BookingTimeForm.requestToBook" />}</>
              ) : isOutOfStock ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
              ) : (
                <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
              )}
            </PrimaryButton>
          )}
          {/* {renderWebsiteButtonMaybe} */}
        </div>
      </div>
    </div>
  )
}

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null
}

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired
  }).isRequired,
  location: shape({
    search: string
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired
}

export default compose(withRouter, injectIntl)(OrderPanel)
