import { fetchModelRequests } from '../../util/api'

// ================ Action types ================ //

export const FETCH_MODEL_REQUESTS = 'app/ModelRequestsPage/FETCH_MODEL_REQUESTS'
export const FETCH_MODEL_REQUESTS_SUCCESS = 'app/ModelRequestsPage/FETCH_MODEL_REQUESTS_SUCCESS'
export const FETCH_MODEL_REQUESTS_ERROR = 'app/ModelRequestsPage/FETCH_MODEL_REQUESTS_ERROR'

// ================ Reducer ================ //

const initialState = {
  modelRequests: null,
  queryInProgress: false,
  queryModelRequestsError: null
}

const modelRequestsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case FETCH_MODEL_REQUESTS:
      return {
        ...state,
        modelRequests: null,
        queryInProgress: true,
        queryModelRequestsError: null
      }
    case FETCH_MODEL_REQUESTS_SUCCESS:
      return {
        ...state,
        modelRequests: payload,
        queryInProgress: false,
        queryModelRequestsError: null
      }
    case FETCH_MODEL_REQUESTS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload)
      return {
        ...state,
        modelRequests: null,
        queryInProgress: false,
        queryModelRequestsError: payload
      }

    default:
      return state
  }
}

export default modelRequestsPageReducer

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)

export const queryModelRequests = () => ({
  type: FETCH_MODEL_REQUESTS,
  payload: null
})

export const queryModelRequestSuccess = (response) => ({
  type: FETCH_MODEL_REQUESTS_SUCCESS,
  payload: response
})

export const queryModelRequestsError = (e) => ({
  type: FETCH_MODEL_REQUESTS_ERROR,
  error: true,
  payload: e
})

export const getModelRequests = () => async (dispatch, getState, sdk) => {
  dispatch(queryModelRequests())
  try {
    const result = await fetchModelRequests()
    dispatch(queryModelRequestSuccess(result.response))
    return result
  } catch (error) {
    dispatch(queryModelRequestsError(error))
  }
}

export const loadData = (params, search, config) => {
  return getModelRequests()
}
