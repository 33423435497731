const addOrReplaceUTM = (url, utmParams) => {
  try {
    if (!url || url === '') return url

    const urlObj = new URL(url)

    // Iterate over the provided UTM parameters and add or update them in the URL
    for (const [key, value] of Object.entries(utmParams)) {
      urlObj.searchParams.set(key, value)
    }

    return urlObj.toString()
  } catch (err) {
    console.error('Error adding or replacing UTM parameters:', err)
    return url
  }
}

export const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

/**
 * { day: string, from: string, to: string, numOfSeats: number }
 */
export const getPractitionerSchedule = (listing) => {
  const availabilityPlanEntries = listing.attributes?.publicData?.availabilityPlan?.entries

  if (availabilityPlanEntries && availabilityPlanEntries.length > 0) {
    return days.map((day) => {
      const currentDay = availabilityPlanEntries.filter((entry) => entry.dayOfWeek === day)[0]
      if (currentDay) {
        const { dayOfWeek, startTime, endTime, seats } = currentDay
        return { day: dayOfWeek, from: startTime, to: endTime, seats }
      } else {
        return { day, from: undefined, to: undefined, seats: 0 }
      }
    })
  }

  return undefined
}

/**
 * string
 */
export const getClinicName = (listing) => {
  let clinicName
  clinicName = listing?.attributes?.publicData?.brandName

  if (!clinicName) {
    clinicName = listing.included?.filter((entry) => entry.type === 'user')[0].attributes?.profile
      ?.publicData?.brandName
  }

  return clinicName
}

export const getListingPhotoUrls = (listing) => {
  // Extract the image IDs from the relationships array in the given order
  const imageIds = listing.relationships?.images?.data.map((image) => image.id)

  // Filter the included array to get image entries and map them by ID
  const imageEntries = listing.included
    ?.filter((entry) => entry.type === 'image')
    .reduce((acc, entry) => {
      acc[entry.id] = entry.attributes?.variants?.default?.url
      return acc
    }, {})

  // Map the image IDs to the URLs based on the order from relationships
  const imageUrls = imageIds?.map((id) => imageEntries[id]).filter((url) => url)

  return imageUrls || []
}

/**
 * string
 */
export const getDescription = (listing) => {
  return listing.attributes?.description
}

/**
 * { latitude: number, longitude: number }
 */
export const getLocationCoordinates = (listing) => {
  const coordinates = listing?.attributes?.geolocation
  if (coordinates) {
    const { lat, lng } = coordinates
    return { lat, lon: lng }
  }

  return undefined
}

/**
 * string
 */
export const getAhpraVerificationNumber = (listing) => {
  return listing.attributes?.publicData?.ahpra_number
}

/**
 * [{ degree: string, school: string, yearCompleted: string }]
 */
export const getEducationRecords = (listing) => {
  return listing.attributes?.publicData?.educationDegree
}

/**
 * string
 */
export const getBookingUrl = (listing, medium) => {
  const utmParams = {
    utm_source: 'Stella',
    utm_medium: medium || 'ListingPage',
    utm_campaign: 'BookButton'
  }
  const result = addOrReplaceUTM(listing.attributes?.publicData?.bookingURL, utmParams)
  return result
}

/**
 * string
 */
export const getInsuranceName = (listing) => {
  return listing.attributes?.publicData?.insurance
}

/**
 * string
 */
export const getIndustry = (listing) => {
  return listing.attributes?.publicData?.industry
}

/**
 * { address: string, building: string}
 */
export const getLocationName = (listing) => {
  return listing.attributes?.publicData?.location
}

/**
 * [string]
 * e.g. ["onsite-parking", "paid-parking"]
 */
export const getParkingOptions = (listing) => {
  return listing.attributes?.publicData?.parking
}

/**
 * string
 * Two options: "individualPractitioner" or "clinicPractitioner"
 */
export const getRole = (listing) => {
  const role = listing.attributes?.publicData?.role

  if (role) {
    return role === 'provider' ? 'individualPractitioner' : 'clinicPractitioner'
  }

  return undefined
}

/**
 * [string]
 * e.g. ["hyperpigmentation-expert", "anti-aging"]
 */
export const getSpecializations = (listing) => {
  return listing.attributes?.publicData?.specializations
}

/**
 * string
 */
export const getTrainings = (listing) => {
  return listing.attributes?.publicData?.training
}

/**
 * number
 */
export const getYearsOfExperience = (listing) => {
  return listing.attributes?.publicData?.years_of_experience
}

/**
 * string
 */
export const getWorkHistory = (listing) => {
  return listing.attributes?.publicData?.workHistory
}

/**
 * string
 * e.g. "cancel-24"
 */
export const getCancellationPolicy = (listing) => {
  return listing.attributes?.publicData?.cancellation_policy
}

/**
 * string
 */
export const getListingId = (listing) => {
  return listing.id
}

export const getTitle = (listing) => {
  return listing.attributes?.title
}

/**
 * number
 */
export const getNumberOfHits = (listings) => {
  return listings.hits.length
}

export const getProfilePhotoUrl = (listing) => {
  const profileImageId = listing.included?.filter((entry) => entry.type === 'user')[0].relationships
    ?.profileImage?.data?.id

  if (profileImageId) {
    return listing.included?.filter((entry) => entry.id === profileImageId)[0].attributes?.variants
      ?.default?.url
  }

  return undefined
}

export const hasHundredDollarsCreditOffer = (listing) => {
  return listing.attributes?.publicData?.special_offers_enum === 'two_by_fifty'
}

export const hasOnlineConsultationOffer = (listing) => {
  return listing.attributes?.publicData?.onlineConsultFlag
}

export const getPractitionerName = (listing) => {
  let practitionerName
  practitionerName = listing?.attributes?.publicData?.practitionerName

  if (!practitionerName) {
    practitionerName = getIncluded(listing, 'user')?.attributes?.profile?.publicData
      ?.practitionerName
  }

  return (
    practitionerName || getIncluded(listing, 'user')?.attributes?.profile?.displayName || 'unknown'
  )
}

const getIncluded = (listing, type) => {
  const result = listing.included.filter((entry) => entry.type === type)
  if (result.length > 0) {
    return result[0]
  }

  return null
}

export const getAbbreviatedPractitionerName = (listing) => {
  getIncluded(listing, 'user')?.attributes?.profile?.abbreviatedName || '??'
}

export const getPractitionerAverageRating = (listing) => {
  const averageRating = listing.attributes.metadata?.averageRating

  if (averageRating) {
    return averageRating
  }

  return 0
}

export const getPractitionerReviewsCount = (listing) => {
  const reviewsCount = listing.attributes.metadata?.numReviews

  if (reviewsCount) {
    return reviewsCount
  }

  return 0
}

export const getWebsiteLink = (listing) => {
  const result = addOrReplaceUTM(listing.attributes?.publicData?.websiteURL, {
    utm_source: 'Stella',
    utm_medium: 'ListingPage',
    utm_campaign: 'WebsiteLink'
  })

  return result
}

export const getSocialLinks = (listing) => {
  const links = []
  const instagramLink = listing.attributes?.publicData?.instagramLink
  const websiteLink = getWebsiteLink(listing)

  if (instagramLink) {
    links.push({
      type: 'instagram',
      url: instagramLink
    })
  }

  if (websiteLink) {
    links.push({
      type: 'website',
      url: websiteLink
    })
  }

  return links
}

export const getNumberOfFavoredClients = (listing) => {
  return listing.attributes?.publicData?.numFavorite || 0
}

export const getIntroductoryVideoId = (listing) => {
  return listing.attributes?.publicData?.videoId
}

export const getOtherTrainingCertifications = (listing) => {
  return listing.attributes?.publicData?.training_certifications
}

export const getAreas = (listing) => {
  return listing.attributes?.publicData?.areas || []
}

export const getTreatments = (listing) => {
  const jsonTreatments = listing.attributes?.publicData?.treatment
  if (jsonTreatments) {
    const treatments = JSON.parse(jsonTreatments)
    return treatments
  }

  return {}
}

export const getPractitionerId = (listing) => {
  return listing.relationships.author.data.id
}

export const getListingState = (listing) => {
  return listing.attributes.state
}

export const mapListingsToCommonStructure = (listings) => {
  return listings
    .filter((listing) => listing.attributes && listing.attributes.publicData)
    .map((listing) => ({
      id: getListingId(listing),
      clinicName: getClinicName(listing),
      practitionerName: getPractitionerName(listing),
      practitionerId: getPractitionerId(listing),
      abbreviatedPractitionerName: getAbbreviatedPractitionerName(listing),
      averageRating: getPractitionerAverageRating(listing),
      reviewsCount: getPractitionerReviewsCount(listing),
      schedule: getPractitionerSchedule(listing),
      title: getTitle(listing),
      description: getDescription(listing),
      location: {
        coordinates: getLocationCoordinates(listing),
        name: getLocationName(listing)
      },
      ahpraVerificationNumber: getAhpraVerificationNumber(listing),
      education: getEducationRecords(listing),
      insuranceName: getInsuranceName(listing),
      experience: getYearsOfExperience(listing),
      workHistoryDescription: getWorkHistory(listing),
      cancellationPolicy: getCancellationPolicy(listing),
      parkingOptions: getParkingOptions(listing),
      role: getRole(listing),
      specializations: getSpecializations(listing),
      training: getTrainings(listing),
      otherTrainingCertifications: getOtherTrainingCertifications(listing),
      bookingUrl: getBookingUrl(listing),
      searchBookingUrl: getBookingUrl(listing, 'SearchPage'),
      listingBookingUrl: getBookingUrl(listing, 'ListingPage'),
      industry: getIndustry(listing),
      photos: getListingPhotoUrls(listing),
      practitionerPhoto: getProfilePhotoUrl(listing),
      offers: {
        onlineConsultation: hasOnlineConsultationOffer(listing),
        hundredCredit: hasHundredDollarsCreditOffer(listing)
      },
      socialLinks: getSocialLinks(listing),
      numFavored: getNumberOfFavoredClients(listing),
      introductoryVideoId: getIntroductoryVideoId(listing),
      areas: getAreas(listing),
      treatments: getTreatments(listing),
      state: getListingState(listing)
    }))
}

export const specializations = [
  { value: 'scar-reduction', displayValue: 'Scar Reduction' },
  { value: 'hyperpigmentation-expert', displayValue: 'Hyperpigmentation Expert' },
  { value: 'skin-health', displayValue: 'Skin Health' },
  { value: 'post-surgical-care', displayValue: 'Post-Surgical Care' },
  { value: 'body-positivity', displayValue: 'Body Positivity' },
  { value: 'anti-aging', displayValue: 'Anti-Aging' },
  { value: 'sensitive-skin', displayValue: 'Sensitive Skin' },
  { value: 'rosacea-management', displayValue: 'Rosacea Management' },
  { value: 'skin-barrier', displayValue: 'Skin Barrier' },
  { value: 'aesthetic-wellness', displayValue: 'Aesthetic Wellness' },
  { value: 'youthful-appearance', displayValue: 'Youthful Appearance' },
  { value: 'acne-management', displayValue: 'Acne Management' },
  { value: 'skin-texture', displayValue: 'Skin Texture' },
  { value: 'ethnic-skin', displayValue: 'Ethnic Skin' },
  { value: 'pregnancy-skincare', displayValue: 'Pregnancy Skincare' },
  { value: 'sun-damage', displayValue: 'Sun Damage' },
  { value: 'collagen-boosting', displayValue: 'Collagen Boosting' },
  { value: 'skin-brightening', displayValue: 'Skin Brightening' },
  { value: 'detox-specialist', displayValue: 'Detox Specialist' },
  { value: 'wellness-coach', displayValue: 'Wellness Coach' },
  { value: 'stress-relief', displayValue: 'Stress Relief' },
  { value: 'lifestyle-advisor', displayValue: 'Lifestyle Advisor' },
  { value: 'nutrition-support', displayValue: 'Nutrition Support' },
  { value: 'hydration-nutrition', displayValue: 'Hydration & Nutrition' }
]

export const areas = [
  { value: 'forehead', displayValue: 'Forehead' },
  { value: 'frown', displayValue: 'Frown' },
  { value: 'eyes', displayValue: 'Eyes' },
  { value: 'nose', displayValue: 'Nose' },
  { value: 'lips', displayValue: 'Lips' },
  { value: 'neck', displayValue: 'Neck' },
  { value: 'jawline', displayValue: 'Jawline' }
]

export const experienceLevels = [
  { value: '0,3', displayValue: '0-3' },
  { value: '4,7', displayValue: '4-7' },
  { value: '8,10', displayValue: '8-10' },
  { value: '10,', displayValue: '10+' }
]

export const specialOffers = [
  { value: 'two_by_fifty', displayValue: '$100 Credit', type: 'credit' },
  { value: 'online_consultation', displayValue: 'Online consultation', type: 'consultation' }
]

export const qualificationOrganizations = [
  {
    value: 'accds',
    shortenedDisplayValue: 'AACDS',
    fullDisplayValue: 'Australasian Academy of Cosmetic Dermal Science'
  },
  {
    value: 'aimaAustralia',
    shortenedDisplayValue: 'AIMA',
    fullDisplayValue: 'Australian Islamic Medical Association'
  },
  { value: 'asi', shortenedDisplayValue: 'ASI', fullDisplayValue: 'Aesthetics and Skin Institute' },
  {
    value: 'cpd',
    shortenedDisplayValue: 'CPD',
    fullDisplayValue: 'Cosmetic Professional Development Institute of Australia'
  },
  {
    value: 'dermaInstitute',
    shortenedDisplayValue: 'DI',
    fullDisplayValue: 'Derma Institute'
  },
  {
    value: 'dermaMedical',
    shortenedDisplayValue: 'DM',
    fullDisplayValue: 'Derma Medical'
  },
  {
    value: 'freshClinics',
    shortenedDisplayValue: 'FC',
    fullDisplayValue: 'Fresh Clinics'
  },
  {
    value: 'juvae',
    shortenedDisplayValue: 'Juvae',
    fullDisplayValue: `Juv'ae`
  }
]

export const trainingAndCertificationNames = [
  {
    value: 'afi-complete-injector-online-bundle',
    displayValue: 'AFI Complete Injector Online Bundle'
  }
]
