import { fetchNearbyConsultRequests, replyConsultRequest } from '../../util/api'

// ================ Action types ================ //

export const FETCH_CONSULT_REQUESTS = 'app/ConsultRequestsPage/FETCH_CONSULT_REQUESTS'
export const FETCH_CONSULT_REQUESTS_SUCCESS =
  'app/ConsultRequestsPage/FETCH_CONSULT_REQUESTS_SUCCESS'
export const FETCH_CONSULT_REQUESTS_ERROR = 'app/ConsultRequestsPage/FETCH_CONSULT_REQUESTS_ERROR'

export const REPLY_CONSULT_REQUEST = 'app/ConsultRequestsPage/REPLY_CONSULT_REQUEST'
export const REPLY_CONSULT_REQUEST_SUCCESS = 'app/ConsultRequestsPage/REPLY_CONSULT_REQUEST_SUCCESS'
export const REPLY_CONSULT_REQUEST_ERROR = 'app/ConsultRequestsPage/REPLY_CONSULT_REQUEST_ERROR'

// ================ Reducer ================ //

const initialState = {
  radiusM: 25000,
  consultRequests: null,
  queryInProgress: false,
  queryConsultRequestsError: null,
  replyInProgress: false,
  replyConsultRequestError: null,
  replyResult: null
}

const consultRequestsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case FETCH_CONSULT_REQUESTS:
      return {
        ...state,
        consultRequests: null,
        queryInProgress: true,
        queryConsultRequestsError: null
      }
    case FETCH_CONSULT_REQUESTS_SUCCESS:
      return {
        ...state,
        radiusM: payload.radiusM,
        consultRequests: payload,
        queryInProgress: false,
        queryConsultRequestsError: null
      }
    case FETCH_CONSULT_REQUESTS_ERROR:
      console.error(payload)
      return {
        ...state,
        consultRequests: null,
        queryInProgress: false,
        queryConsultRequestsError: payload
      }
    case REPLY_CONSULT_REQUEST:
      return {
        ...state,
        replyInProgress: true,
        replyConsultRequestError: null,
        replyResult: null
      }
    case REPLY_CONSULT_REQUEST_SUCCESS:
      return {
        ...state,
        replyInProgress: false,
        replyConsultRequestError: null,
        replyResult: payload
      }
    case REPLY_CONSULT_REQUEST_ERROR:
      console.error(payload)
      return {
        ...state,
        replyInProgress: false,
        replyConsultRequestError: payload,
        replyResult: null
      }
    default:
      return state
  }
}

export default consultRequestsPageReducer

// ================ Action creators ================ //

export const queryConsultRequests = () => ({
  type: FETCH_CONSULT_REQUESTS,
  payload: null
})

export const queryConsultRequestSuccess = (response) => ({
  type: FETCH_CONSULT_REQUESTS_SUCCESS,
  payload: response
})

export const queryConsultRequestsError = (e) => ({
  type: FETCH_CONSULT_REQUESTS_ERROR,
  error: true,
  payload: e
})

export const replyConsultRequestStart = () => ({
  type: REPLY_CONSULT_REQUEST
})

export const replyConsultRequestSuccess = (response) => ({
  type: REPLY_CONSULT_REQUEST_SUCCESS,
  payload: response
})

export const replyConsultRequestError = (e) => ({
  type: REPLY_CONSULT_REQUEST_ERROR,
  error: true,
  payload: e
})

export const getConsultRequests = (params) => async (dispatch, getState, sdk) => {
  dispatch(queryConsultRequests())
  try {
    const result = await fetchNearbyConsultRequests(params)
    dispatch(queryConsultRequestSuccess(result.response))
    return result
  } catch (error) {
    console.error(error)
    dispatch(queryConsultRequestsError(error))
  }
}

export const replyToConsultRequest = (params) => async (dispatch, getState, sdk) => {
  dispatch(replyConsultRequestStart())
  try {
    const result = await replyConsultRequest(params)
    dispatch(replyConsultRequestSuccess(result))
    return result
  } catch (error) {
    console.error('replyToConsultRequest:', error)
    dispatch(replyConsultRequestError(error))
  }
}

export const loadData = (params, search, config) => {
  return getConsultRequests()
}
