import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { authenticationInProgress, logout } from '../../../ducks/auth.duck'
import { Topbar } from '../../../components/mui/Topbar'

export const TopbarContainerComponent = ({
  authInProgress,
  currentUser,
  history,
  isAuthenticated,
  location,
  onLogout,
  ...restProps
}) => {
  return (
    <Topbar
      authInProgress={authInProgress}
      currentUser={currentUser}
      history={history}
      isAuthenticated={isAuthenticated}
      location={location}
      onLogout={onLogout}
      {...restProps}
    />
  )
}

const mapStateToProps = (state) => {
  const { isAuthenticated, authScopes } = state.auth
  const { currentUser } = state.user

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    authScopes
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLogout: (historyPush) => dispatch(logout(historyPush))
})

export const TopbarContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TopbarContainerComponent)
