import { ThemeProvider } from '@material-ui/styles'
import { any, string } from 'prop-types'
import React, { useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'

// react-dates needs to be initialized before using any react-dates component
// https://github.com/airbnb/react-dates#initialize
// NOTE: Initializing it here will initialize it also for app.test.js
import loadable from '@loadable/component'
import difference from 'lodash/difference'
import mapValues from 'lodash/mapValues'
import moment from 'moment'
import 'react-dates/initialize'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter, StaticRouter } from 'react-router-dom'

// Configs and store setup
import defaultConfig from './config/configDefault'
import appSettings from './config/settings'
import configureStore from './store'

// utils
import { ConfigurationProvider } from './context/configurationContext'
import { RouteConfigurationProvider } from './context/routeConfigurationContext'
import { mergeConfig } from './util/configHelpers'
import { IncludeScripts } from './util/includeScripts'
import { IntlProvider } from './util/reactIntl'

import { MaintenanceMode } from './components'

// routing
import routeConfiguration from './routing/routeConfiguration'
import Routes from './routing/Routes'

// Sharetribe Web Template uses English translations as default translations.
import defaultMessages from './translations/defaultMicrocopy.json'

// Font Awesome
import { CacheProvider } from '@emotion/react'
import { config } from '@fortawesome/fontawesome-svg-core'
import { ServerStyleSheets } from '@material-ui/core'
import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css' // Import the CSS
import { theme } from './theme'

// Google Tag Manager
import TagManager from 'react-gtm-module'

import createEmotionCache from './util/createEmotionCache'
const cache = createEmotionCache()

config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

// If you want to change the language of default (fallback) translations,
// change the imports to match the wanted locale:
//
//   1) Change the language in the config.js file!
//   2) Import correct locale rules for Moment library
//   3) Use the `messagesInLocale` import to add the correct translation file.
//   4) (optionally) To support older browsers you need add the intl-relativetimeformat npm packages
//      and take it into use in `util/polyfills.js`

// Note that there is also translations in './translations/countryCodes.js' file
// This file contains ISO 3166-1 alpha-2 country codes, country names and their translations in our default languages
// This used to collect billing address in StripePaymentAddress on CheckoutPage

// Step 2:
// If you are using a non-english locale with moment library,
// you should also import time specific formatting rules for that locale
// There are 2 ways to do it:
// - you can add your preferred locale to MomentLocaleLoader or
// - stop using MomentLocaleLoader component and directly import the locale here.
// E.g. for French:
// import 'moment/locale/fr';
// const hardCodedLocale = process.env.NODE_ENV === 'test' ? 'en' : 'fr';

// Step 3:
// The "./translations/defaultMicrocopy.json" has generic English translations
// that should work as a default translation if some translation keys are missing
// from the hosted translation.json (which can be edited in Console). The other files
// (e.g. en.json) in that directory has Biketribe themed translations.
//
// If you are using a non-english locale, point `messagesInLocale` to correct <lang>.json file.
// That way the priority order would be:
//   1. hosted translation.json
//   2. <lang>.json
//   3. defaultMicrocopy.json
//
// I.e. remove "const messagesInLocale" and add import for the correct locale:
// import messagesInLocale from './translations/fr.json';
//
// However, the recommendation is that you translate the defaultMicrocopy.json file and keep it updated.
// That way you can avoid importing <lang>.json into build files, which is better for performance.
const messagesInLocale = {}

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const addMissingTranslations = (sourceLangTranslations, targetLangTranslations) => {
  const sourceKeys = Object.keys(sourceLangTranslations)
  const targetKeys = Object.keys(targetLangTranslations)

  // if there's no translations defined for target language, return source translations
  if (targetKeys.length === 0) {
    return sourceLangTranslations
  }
  const missingKeys = difference(sourceKeys, targetKeys)

  const addMissingTranslation = (translations, missingKey) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey]
  })

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations)
}

// Get default messages for a given locale.
//
// Note: Locale should not affect the tests. We ensure this by providing
//       messages with the key as the value of each message and discard the value.
//       { 'My.translationKey1': 'My.translationKey1', 'My.translationKey2': 'My.translationKey2' }
const isTestEnv = process.env.NODE_ENV === 'test'

const localeMessages = isTestEnv
  ? mapValues(defaultMessages, (val, key) => key)
  : addMissingTranslations(defaultMessages, messagesInLocale)

// For customized apps, this dynamic loading of locale files is not necessary.
// It helps locale change from configDefault.js file or hosted configs, but customizers should probably
// just remove this and directly import the necessary locale on step 2.
const MomentLocaleLoader = (props) => {
  const { children, locale } = props
  const isAlreadyImportedLocale =
    typeof hardCodedLocale !== 'undefined' && locale === hardCodedLocale

  // Moment's built-in locale does not need loader
  const NoLoader = (props) => <>{props.children()}</>

  // The default locale is en (en-US). Here we dynamically load one of the other common locales.
  // However, the default is to include all supported locales package from moment library.
  const MomentLocale =
    ['en', 'en-US'].includes(locale) || isAlreadyImportedLocale
      ? NoLoader
      : ['fr', 'fr-FR'].includes(locale)
        ? loadable.lib(() => import(/* webpackChunkName: "fr" */ 'moment/locale/fr'))
        : ['de', 'de-DE'].includes(locale)
          ? loadable.lib(() => import(/* webpackChunkName: "de" */ 'moment/locale/de'))
          : ['es', 'es-ES'].includes(locale)
            ? loadable.lib(() => import(/* webpackChunkName: "es" */ 'moment/locale/es'))
            : ['fi', 'fi-FI'].includes(locale)
              ? loadable.lib(() => import(/* webpackChunkName: "fi" */ 'moment/locale/fi'))
              : ['nl', 'nl-NL'].includes(locale)
                ? loadable.lib(() => import(/* webpackChunkName: "nl" */ 'moment/locale/nl'))
                : loadable.lib(
                    () => import(/* webpackChunkName: "locales" */ 'moment/min/locales.min')
                  )

  return (
    <MomentLocale>
      {() => {
        // Set the Moment locale globally
        // See: http://momentjs.com/docs/#/i18n/changing-locale/
        moment.locale(locale)
        return children
      }}
    </MomentLocale>
  )
}

const Configurations = (props) => {
  const { appConfig, children } = props
  const routeConfig = routeConfiguration(appConfig.layout)
  const locale = isTestEnv ? 'en' : appConfig.localization.locale

  return (
    <ConfigurationProvider value={appConfig}>
      <MomentLocaleLoader locale={locale}>
        <RouteConfigurationProvider value={routeConfig}>{children}</RouteConfigurationProvider>
      </MomentLocaleLoader>
    </ConfigurationProvider>
  )
}

const MaintenanceModeError = (props) => {
  const { locale, messages, helmetContext } = props
  return (
    <IntlProvider locale={locale} messages={messages} textComponent="span">
      <HelmetProvider context={helmetContext}>
        <MaintenanceMode />
      </HelmetProvider>
    </IntlProvider>
  )
}

const initializeTagManager = (appConfig) => {
  const { analytics } = appConfig
  if (analytics.googleTagManagerId) {
    console.log('Initializing Google Tag Manager')
    TagManager.initialize({ gtmId: analytics.googleTagManagerId })

    extractUtmParams()
  }
}

const extractUtmParams = () => {
  if (window && window.location) {
    // Get the URL's query string
    const queryString = window.location.search

    // Use URLSearchParams to handle the query string
    const urlParams = new URLSearchParams(queryString)

    // Object to hold UTM parameters
    const utmParams = {}

    // List of UTM parameters we are interested in
    const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

    // Iterate over each UTM parameter and add to the utmParams object if present
    utmKeys.forEach((key) => {
      if (urlParams.has(key)) {
        utmParams[key] = urlParams.get(key)
      } else {
        utmParams[key] = ''
      }
    })

    // Save UTM parameters to session storage
    sessionStorage.setItem('utmParams', JSON.stringify(utmParams))
  }
}

export const ClientApp = (props) => {
  const { store, hostedTranslations = {}, hostedConfig = {} } = props
  const appConfig = mergeConfig(hostedConfig, defaultConfig)

  // Show MaintenanceMode if the mandatory configurations are not available
  if (!appConfig.hasMandatoryConfigurations) {
    return (
      <MaintenanceModeError
        locale={appConfig.localization.locale}
        messages={{ ...localeMessages, ...hostedTranslations }}
      />
    )
  }

  // Marketplace color and branding image comes from configs
  // If set, we need to create CSS Property and set it to DOM (documentElement is selected here)
  // This provides marketplace color for everything under <html> tag (including modals/portals)
  // Note: This is also set on Page component to provide server-side rendering.
  const elem = window.document.documentElement
  if (appConfig.branding.marketplaceColor) {
    elem.style.setProperty('--marketplaceColor', appConfig.branding.marketplaceColor)
    elem.style.setProperty('--marketplaceColorDark', appConfig.branding.marketplaceColorDark)
    elem.style.setProperty('--marketplaceColorLight', appConfig.branding.marketplaceColorLight)
  }
  // This gives good input for debugging issues on live environments, but with test it's not needed.
  const logLoadDataCalls = appSettings?.env !== 'test'

  useEffect(() => {
    initializeTagManager(appConfig)
  }, [])

  return (
    <CacheProvider value={cache}>
      <Configurations appConfig={appConfig}>
        <IntlProvider
          locale={appConfig.localization.locale}
          messages={{ ...localeMessages, ...hostedTranslations }}
          textComponent="span"
        >
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <HelmetProvider>
                <IncludeScripts config={appConfig} />
                <BrowserRouter>
                  <Routes
                    routes={routeConfiguration(appConfig.layout)}
                    logLoadDataCalls={logLoadDataCalls}
                  />
                </BrowserRouter>
              </HelmetProvider>
            </ThemeProvider>
          </Provider>
        </IntlProvider>
      </Configurations>
    </CacheProvider>
  )
}

ClientApp.propTypes = { store: any.isRequired }

export const ServerApp = (props) => {
  const { url, context, helmetContext, store, hostedTranslations = {}, hostedConfig = {} } = props
  const appConfig = mergeConfig(hostedConfig, defaultConfig)
  HelmetProvider.canUseDOM = false

  useEffect(() => {
    initializeTagManager(appConfig)
  })

  // Show MaintenanceMode if the mandatory configurations are not available
  if (!appConfig.hasMandatoryConfigurations) {
    return (
      <MaintenanceModeError
        locale={appConfig.localization.locale}
        messages={{ ...localeMessages, ...hostedTranslations }}
        helmetContext={helmetContext}
      />
    )
  }

  return (
    <Configurations appConfig={appConfig}>
      <IntlProvider
        locale={appConfig.localization.locale}
        messages={{ ...localeMessages, ...hostedTranslations }}
        textComponent="span"
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <HelmetProvider context={helmetContext}>
              <IncludeScripts config={appConfig} />
              <StaticRouter location={url} context={context}>
                <Routes />
              </StaticRouter>
            </HelmetProvider>
          </ThemeProvider>
        </Provider>
      </IntlProvider>
    </Configurations>
  )
}

ServerApp.propTypes = {
  url: string.isRequired,
  context: any.isRequired,
  store: any.isRequired
}

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (
  url,
  serverContext,
  preloadedState,
  hostedTranslations,
  hostedConfig,
  collectChunks
) => {
  const store = configureStore(preloadedState)
  const helmetContext = {}

  const sheets = new ServerStyleSheets() // Create an instance of ServerStyleSheets

  // Wrap the component tree in sheets.collect to gather the CSS styles
  const WithChunks = sheets.collect(
    collectChunks(
      <CacheProvider value={cache}>
        <ServerApp
          url={url}
          context={serverContext}
          helmetContext={helmetContext}
          store={store}
          hostedTranslations={hostedTranslations}
          hostedConfig={hostedConfig}
        />
      </CacheProvider>
    )
  )

  const body = ReactDOMServer.renderToString(WithChunks)
  const css = sheets.toString() // Extract the collected styles as a string
  const { helmet: head } = helmetContext

  return { head, body, css } // Return the collected styles along with head and body
}
