export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const highlightMatchingSubstring = (originalString, inputString) => {
  if (originalString) {
    if (!inputString.trim()) return originalString

    const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

    const createRegexPattern = (input) => {
      const escapedInput = escapeRegExp(input)
      const regexPattern = escapedInput
        .split(' ')
        .map((word) => `(${word})`)
        .join('|')
      return new RegExp(regexPattern, 'gi')
    }

    const regex = createRegexPattern(inputString)

    const matches = [...originalString.matchAll(regex)]
    if (matches.length === 0) return originalString

    let highlightedText = ''
    let lastIndex = 0

    matches.forEach((match) => {
      const matchIndex = match.index
      const matchLength = match[0].length

      highlightedText += originalString.slice(lastIndex, matchIndex) // Append non-matching segment
      highlightedText += `<span style="color: #FF606D;">${match[0]}</span>` // Highlight matching segment

      lastIndex = matchIndex + matchLength
    })

    highlightedText += originalString.slice(lastIndex) // Append the remaining part of the text

    return highlightedText
  }

  return originalString
}
