import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles({
  container: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: ({ isClickable }) => (isClickable ? 'pointer' : 'default')
    }
  }
})

export const useIconStyles = ({ className, isClickable }) => {
  const classes = useStyles({ isClickable })
  const joinedClasses = classNames(classes.container, className)

  return { joinedClasses }
}
