/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import classNames from 'classnames'
import { arrayOf, bool, node, shape, string } from 'prop-types'
import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { FieldCheckbox, ValidationError } from '../../components'

import { faRobot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Chip from '../Chip/Chip'
import css from './FieldCheckboxGroup.module.css'

const FieldCheckboxRenderer = (props) => {
  const {
    className,
    rootClassName,
    label,
    showChip,
    required,
    writerIcon,
    optionLabelClassName,
    twoColumns,
    id,
    fields,
    options,
    meta
  } = props

  const classes = classNames(rootClassName || css.root, className)
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list

  return (
    <fieldset className={classes}>
      {label ? (
        <legend>
          {label}{' '}
          {writerIcon && (
            <Chip
              label=""
              icon={<FontAwesomeIcon icon={faRobot} size="sm" color="#000" />}
              borderColor={'#000'}
              color="#fffff"
            />
          )}
          {'  '}
          {showChip && required && <Chip label="Required" color="#FF7070" />}
        </legend>
      ) : null}
      <ul className={listClasses}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`
          const textClassName = optionLabelClassName
          const textClassNameMaybe = textClassName ? { textClassName } : {}
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox
                id={fieldId}
                name={fields.name}
                label={option.label}
                value={option.key}
                {...textClassNameMaybe}
              />
            </li>
          )
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  )
}

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  required: false,
  showChip: false,
  twoColumns: false
}

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  required: bool,
  showChip: bool,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired
    })
  ).isRequired,
  twoColumns: bool
}

const FieldCheckboxGroup = (props) => <FieldArray component={FieldCheckboxRenderer} {...props} />

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroup.propTypes = {
  name: string.isRequired
}

export default FieldCheckboxGroup
