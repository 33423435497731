import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      borderColor: ({ isActive }) => (isActive ? palette.primary.main : palette.grey['350'])
    },
    text: {
      fontSize: '14px',
      lineHeight: '20px',
      padding: 0,
      letterSpacing: 0,
      color: ({ isActive }) => (isActive ? palette.primary.main : palette.blue['800'])
    }
  }
})

export const useMobileConsultationListItemStyles = ({ isActive }) => {
  const classes = useStyles({ isActive })
  return { classes }
}
