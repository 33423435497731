import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px'
    },
    categoryHeader: {
      fontSize: '16px',
      fontWeight: 500,
      color: palette.blue['800'],
      padding: 0,
      letterSpacing: 0
    },
    categoryContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    },
    listItemsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px'
    },
    mobileChipsItemsContainer: {
      display: 'flex',
      gap: '8px',
      flexWrap: 'wrap'
    },
    noConsultationsFoundText: {
      fontSize: '14px',
      fontHeight: '20px',
      color: palette.blue['300']
    },
    noConsultationsFoundContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px'
    }
  }
})

export const useConsultationResultListStyles = () => {
  const classes = useStyles()
  return { classes }
}
