import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => {
  const { breakpoints, palette } = theme

  return {
    container: {
      display: 'none',
      [breakpoints.down('sm')]: {
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%'
      },
      zIndex: 1100
    },
    label: {
      fontFamily: 'Inter',
      fontWeight: 400,
      color: palette.text.disabled,
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }
})

export const useBottomNavigationStyles = () => {
  const classes = useStyles()

  const joinedContainerClasses = classNames(classes.container)
  const joinedLabelClasses = classNames(classes.label)

  return { joinedContainerClasses, joinedLabelClasses }
}
