import PropTypes from 'prop-types'
import React from 'react'

// External link that opens in a new tab/window, ensuring that the
// opened page doesn't have access to the current page.
//
// See: https://mathiasbynens.github.io/rel-noopener/
const ExternalLink = (props) => {
  const { children, target = '_blank', ...rest } = props
  return (
    <a {...rest} target={target} rel="noopener noreferrer">
      {children}
    </a>
  )
}

ExternalLink.defaultProps = { children: null }

const { node } = PropTypes

ExternalLink.propTypes = { children: node }

export default ExternalLink
