import classNames from 'classnames'
import { oneOf, string } from 'prop-types'
import React from 'react'
import { ExternalLink, Logo } from '../../components'
import { useConfiguration } from '../../context/configurationContext'

import css from './LinkedLogo.module.css'

const LinkedLogo = (props) => {
  const { className, rootClassName, logoClassName, format, alt, privateMode, ...rest } = props
  const config = useConfiguration()
  const classes = classNames(rootClassName || css.root, className)

  if (privateMode) {
    return (
      <span className={classes}>
        <Logo format={format} className={logoClassName || css.logo} alt={alt} />
      </span>
    )
  } else {
    return (
      <ExternalLink href="https://seestella.com.au" target="_self" className={classes} {...rest}>
        <Logo format={format} className={logoClassName || css.logo} alt={alt} />
      </ExternalLink>
    )
  }
}

LinkedLogo.defaultProps = {
  className: null,
  rootClassName: null,
  logoClassName: null,
  format: 'desktop'
}

LinkedLogo.propTypes = {
  className: string,
  rootClassName: string,
  logoClassName: string,
  format: oneOf(['desktop', 'mobile'])
}

export default LinkedLogo
