import React from 'react'
import './SectionStars.css'

const SectionStars = ({ rating }) => {
  const totalStars = 5
  const highlightedStars = Math.floor(rating)
  const isHalfStar = rating % 1 !== 0

  const renderStars = () => {
    const stars = []

    for (let i = 0; i < totalStars; i++) {
      if (i < highlightedStars) {
        stars.push(
          <span key={i} className="highlighted-star">
            &#9733;
          </span>
        )
      } else if (i === highlightedStars && isHalfStar) {
        stars.push(
          <span key={i} className="half-star">
            &#9733;
          </span>
        )
      } else {
        stars.push(
          <span key={i} className="grey-star">
            &#9733;
          </span>
        )
      }
    }

    return stars
  }

  return <div className="review-stars">{renderStars()}</div>
}

export default SectionStars
