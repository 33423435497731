import { makeStyles } from '@material-ui/core'

import { theme } from '../../../../theme'

const useStyles = makeStyles(() => {
  const { palette } = theme

  return {
    defaultAvatar: {
      backgroundColor: palette.primary.main
    }
  }
})

export const useAvatarStyles = () => {
  const classes = useStyles()

  return { classes }
}
