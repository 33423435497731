import { Typography } from '@material-ui/core'
import { useState, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { useMobileConsultationListItemStyles } from './hooks'

import { Chip } from '../../../../../Chip'

import { highlightMatchingSubstring } from '../../../../../../../util/new/string'

export const MobileConsultationListItem = ({
  consultation,
  consultationId,
  consultationInputValue,
  category,
  onClick
}) => {
  const isActive = consultation === consultationInputValue
  const [consultationValue, setConsultationValue] = useState(consultation)
  const { classes } = useMobileConsultationListItemStyles({ isActive })

  useEffect(() => {
    setConsultationValue(
      ReactHtmlParser(highlightMatchingSubstring(consultation, consultationInputValue))
    )
  }, [consultationInputValue])

  const clickHandler = () => {
    onClick({ id: consultationId, name: consultation, category })
  }

  return (
    <Chip
      label={<Typography className={classes.text}>{consultationValue}</Typography>}
      onClick={clickHandler}
      className={classes.container}
    />
  )
}
