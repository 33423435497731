import classNames from 'classnames'
import pickBy from 'lodash/pickBy'
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types'
import React, { Component } from 'react'

import appSettings from '../../config/settings'
import { useConfiguration } from '../../context/configurationContext'
import { useRouteConfiguration } from '../../context/routeConfigurationContext'

import {
  Button,
  LimitedAccessBanner,
  LinkedLogo,
  Modal,
  ModalMissingInformation
} from '../../components'
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl'
import { createResourceLocatorString, pathByRouteName } from '../../util/routes'
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search'
import { propTypes } from '../../util/types'
import { withViewport } from '../../util/uiHelpers'
import { parse, stringify } from '../../util/urlHelpers'

import MenuIcon from './MenuIcon'
import SearchIcon from './SearchIcon'
import TopbarDesktop from './TopbarDesktop/TopbarDesktop'
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu'
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm'

import NotificationBanner from '../NotificationBanner/NotificationBanner'
import css from './Topbar.module.css'

const MAX_MOBILE_SCREEN_WIDTH = 768

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props
  const { pathname, search, state } = location
  const searchString = `?${stringify({
    [modalStateParam]: 'open',
    ...parse(search)
  })}`
  history.push(`${pathname}${searchString}`, state)
}

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props
  const { pathname, search, state } = location
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam
  })
  const stringified = stringify(queryParams)
  const searchString = stringified ? `?${stringified}` : ''
  history.push(`${pathname}${searchString}`, state)
}

const GenericError = (props) => {
  const { show } = props
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show
  })
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  )
}

GenericError.propTypes = {
  show: bool.isRequired
}

class TopbarComponent extends Component {
  constructor(props) {
    super(props)
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this)
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this)
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this)
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  componentDidMount() {
    if (window && window.sessionStorage) {
      this.privateMode = window.sessionStorage.getItem('privateMode') == 'true' ?? false
    }
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu')
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu')
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch')
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch')
  }

  handleSubmit() {
    const { history, routeConfiguration } = this.props

    history.push(createResourceLocatorString('SearchPage', routeConfiguration))
  }

  handleLogout() {
    const { onLogout, history, routeConfiguration } = this.props
    onLogout().then(() => {
      const path = pathByRouteName('SearchPage', routeConfiguration)

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path)
      } else if (typeof window !== 'undefined') {
        window.location = path
      }

      console.log('logged out') // eslint-disable-line
    })
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      config
    } = this.props

    const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds']
    })

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open'
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open'

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
        config={config}
      />
    )

    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords }
      }

      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds
      return {
        location: locationFieldsPresent
          ? {
              search: address,
              selectedPlace: { address, origin, bounds }
            }
          : null
      }
    }
    const initialSearchFormValues = topbarSearcInitialValues()

    const classes = classNames(rootClassName || css.root, className)

    return (
      <div className={classes}>
        <NotificationBanner currentUser={currentUser} />
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        {!this.privateMode ? (
          <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
            <Button
              rootClassName={css.menu}
              onClick={this.handleMobileMenuOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              <MenuIcon className={css.menuIcon} />
              {notificationDot}
            </Button>
            <LinkedLogo
              format={'mobile'}
              alt={intl.formatMessage({ id: 'Topbar.logoIcon' })}
              privateMode={this.privateMode}
            />
            <Button
              rootClassName={css.searchMenu}
              onClick={this.handleMobileSearchOpen}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              <SearchIcon className={css.searchMenuIcon} />
            </Button>
          </div>
        ) : (
          <>
            <div
              className={classNames(mobileRootClassName || css.container, mobileClassName)}
              style={{ justifyContent: 'center' }}
            >
              <LinkedLogo
                format={'mobile'}
                alt={intl.formatMessage({ id: 'Topbar.logoIcon' })}
                privateMode={this.privateMode}
              />
            </div>
          </>
        )}
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            appConfig={config}
            privateMode={this.privateMode}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
              appConfig={config}
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <GenericError show={showGenericError} />
      </div>
    )
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: []
}

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired
  }).isRequired,
  location: shape({
    search: string.isRequired
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired
}

const EnhancedTopbar = (props) => {
  const config = useConfiguration()
  const routeConfiguration = useRouteConfiguration()
  const intl = useIntl()
  return (
    <TopbarComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      {...props}
    />
  )
}

const Topbar = withViewport(EnhancedTopbar)
Topbar.displayName = 'Topbar'

export default Topbar
