import { faAward } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import css from './SpecialOfferBanner.module.css'

export const SpecialOfferTypeKey = {
  two_by_fifty: 'two_by_fifty'
}

export const SpecialOfferType = {
  two_by_fifty: {
    imageLabel: 'OFFERING $100 CREDIT',
    label: 'GET YOUR $100 CREDIT',
    info: '$50 Credit Towards Your First Two Sessions',
    terms: 'One per person. Show credit email on arrival. Participating Practitioners only.',
    cta: 'Get $100 Credit',
    icon: faAward
  }
}

export const SpecialOfferImageBannner = (props) => {
  const { specialOfferEnum } = props
  const specialOfferType = SpecialOfferType[specialOfferEnum]

  if (!specialOfferType) {
    return null
  }

  return (
    <div className={css.specialOfferImageBanner}>
      <div className={css.specialOfferImageBannerContent}>
        <FontAwesomeIcon icon={specialOfferType.icon} size="lg" />
        <div>{specialOfferType.imageLabel}</div>
      </div>
    </div>
  )
}

export const SpecialOfferListingBannner = (props) => {
  const { specialOfferEnum, onClick } = props
  const specialOfferType = SpecialOfferType[specialOfferEnum]

  if (!specialOfferType) {
    return null
  }

  return (
    <div className={css.specialOfferListingBanner} style={{ cursor: 'pointer' }} onClick={onClick}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <div className={css.specialOfferListingBannerContent}>
            <FontAwesomeIcon icon={specialOfferType.icon} size="lg" />
            <div>{specialOfferType.label}</div>
          </div>
          <div className={css.specialoffListingBannerLearnMore}>{specialOfferType.info}</div>
          <div className={css.specialoffListingBannerTerms}>{specialOfferType.terms}</div>
        </div>
        <div style={{ marginRight: '5px', marginLeft: '5px' }}>
          <a>GET</a>
        </div>
      </div>
    </div>
  )
}
