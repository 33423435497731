import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import './InfoModal.css'

const IconCheck = () => {
  return (
    <svg width="9px" height="9px" xmlns="http://www.w3.org/2000/svg" className="checkIcon">
      <path
        className="marketplaceFill"
        d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
        fillRule="evenodd"
      />
    </svg>
  )
}

function InfoModal({ renderTriggerComponent, title, description, image, list, cta }) {
  const [screenHeight, setScreenHeight] = useState(null)

  useEffect(() => {
    function handleResize() {
      setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Popup trigger={renderTriggerComponent} modal nested lockScroll>
      {(close) => (
        <div className="modal">
          <div className="header">
            <div className="title" style={{ marginRight: '10px' }}>
              {title}{' '}
            </div>
            <button className="close" onClick={close}>
              &times;
            </button>
          </div>
          <div className="content" style={{ maxHeight: screenHeight - 150, overflowY: 'auto' }}>
            {description}
            {list && list.length > 0 ? <div className="listTitle">Courses Completed</div> : null}
            <ul className="root">
              {list &&
                list.map((item) => (
                  <li>
                    <div className="iconWrapper">
                      <IconCheck />

                      <div className="labelWrapper">
                        <div className="selectedLabel">{item}</div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="image" style={{ margin: '10px' }}>
              {image ? <img width={250} src={image} alt={title} /> : null}
            </div>
            <div
              style={{
                margin: '10px',
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {cta}
            </div>
          </div>
        </div>
      )}
    </Popup>
  )
}

export default InfoModal
