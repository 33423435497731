import { useState } from 'react'
import { InputAdornment } from '@material-ui/core'
import { faLocationDot, faXmark } from '@fortawesome/free-solid-svg-icons'

import { useLocationInputFieldStyles } from './hooks'

import { InputField } from '../../'

import { Icon } from '../../../Icon'

export const LocationInputField = ({
  value,
  setValue,
  placeholder,
  className,
  placeholderTextClassName,
  inputTextStyle,
  InputProps,
  variant = 'outlined',
  onChange,
  onFocus,
  onBlur,
  fullWidth
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const { joinedContainerClasses, joinedPlaceholderTextClasses, classes } =
    useLocationInputFieldStyles({
      className,
      placeholderTextClassName
    })

  const focusHandler = () => {
    setIsFocused(true)
    onFocus()
  }

  const blurHandler = () => {
    setIsFocused(false)
    onBlur()
  }

  const clearHandler = () => {
    setValue('')
  }

  const clearMouseDownHandler = (event) => {
    event.preventDefault()
  }

  return (
    <InputField
      variant={variant}
      value={value}
      setValue={setValue}
      placeholder={placeholder}
      fullWidth={fullWidth}
      InputProps={{
        className: joinedContainerClasses,
        classes: {
          input: joinedPlaceholderTextClasses
        },
        style: {
          fontSize: '16px',
          ...inputTextStyle
        },
        startAdornment: (
          <InputAdornment position="start">
            <Icon icon={faLocationDot} className={classes.locationDotIcon} />
          </InputAdornment>
        ),
        endAdornment: isFocused && value && (
          <InputAdornment position="end" onClick={clearHandler}>
            <Icon
              icon={faXmark}
              className={classes.xMarkIcon}
              onMouseDown={clearMouseDownHandler}
            />
          </InputAdornment>
        ),
        ...InputProps
      }}
      onChange={onChange}
      onFocus={focusHandler}
      onBlur={blurHandler}
    />
  )
}
