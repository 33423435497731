export const getReviewDate = (review) => {
  return review.createdAt._seconds
}

export const getUpdateDate = (review) => {
  return review.updatedAt._seconds
}

export const getReviewId = (review) => {
  return review.id
}

export const getClientName = (review) => {
  return review.user.name
}

export const getTreatment = (review) => {
  return review.treatment
}

export const getReviewText = (review) => {
  return review.review
}

export const getRating = (review) => {
  return review.rating
}

export const getProfileImageUrl = (review) => {
  return review.user.profileImage
}

export const getStatus = (review) => {
  return review.status
}

export const getTreatmentDate = (review) => {
  return review.treatmentDate
}

export const getResponse = (review) => {
  const response = review.response

  if (response) {
    const responseText = response.comment
    const createdAt = response.commentTimestamp._seconds
    const practitionerName = response.user.name
    const status = response.commentStatus
    const numHelpful = response.numHelpful || 0
    const numUnhelpful = response.numUnhelpful || 0
    const practitionerId = response.user.id
    const profileImageUrl = response.user.profileImage
    const abbreviatedPractitionerName = getAbbreviatedName(response.user.name)

    return {
      responseText,
      createdAt,
      practitionerName,
      status,
      numHelpful,
      numUnhelpful,
      practitionerId,
      profileImageUrl,
      abbreviatedPractitionerName
    }
  }

  return undefined
}

export const getNumberOfReviewHelpfulMarks = (review) => {
  return review.numHelpful
}

export const getNumberOfReviewUnhelpfulMarks = (review) => {
  return review.numUnhelpful
}

export const getNumberOfResponseHelpfulMarks = (review) => {
  return review.response.numHelpful
}

export const getNumberOfResponseUnhelpfulMarks = (review) => {
  return review.response.numUnhelpful
}

export const getClientId = (review) => {
  return review.user.id
}

export const getAbbreviatedClientName = (review) => {
  return getAbbreviatedName(review.user.name)
}

export const mapReviewsToCommonFormat = (reviews) => {
  return reviews.map((review) => ({
    id: getReviewId(review),
    createdAt: getReviewDate(review),
    updatedAt: getUpdateDate(review),
    clientId: getClientId(review),
    clientName: getClientName(review),
    abbreviatedClientName: getAbbreviatedClientName(review),
    treatment: getTreatment(review),
    treatmentDate: getTreatmentDate(review),
    reviewText: getReviewText(review),
    rating: getRating(review),
    profileImageUrl: getProfileImageUrl(review),
    status: getStatus(review),
    response: getResponse(review),
    numHelpful: getNumberOfReviewHelpfulMarks(review),
    numUnhelpful: getNumberOfReviewUnhelpfulMarks(review)
  }))
}

const getAbbreviatedName = (name) => {
  return name
    .split(' ')
    .map((namePart) => namePart.charAt(0).toUpperCase())
    .join('')
}
