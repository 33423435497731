import classNames from 'classnames'
import { bool, func, string } from 'prop-types'
import React from 'react'

import { useConfiguration } from '../../context/configurationContext'

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components'
import { formatMoney } from '../../util/currency'
import { ensureListing, ensureUser } from '../../util/data'
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl'
import { richText } from '../../util/richText'
import { types as sdkTypes } from '../../util/sdkLoader'
import { propTypes } from '../../util/types'
import { isArrayLength, lazyLoadWithDimensions } from '../../util/uiHelpers'
import { createSlug, parse } from '../../util/urlHelpers'

import SectionStars from '../../containers/ListingPage/SectionStars'
import { SpecialOfferImageBannner } from '../SpecialOfferBanner/SpecialOfferBanner'
import css from './ListingCard.module.css'

const MIN_LENGTH_FOR_LONG_WORDS = 10
const { Money } = sdkTypes

export const findMinPrice = (services) => {
  if (!Array.isArray(services) || services.length === 0) {
    return null // Handle invalid input or empty array
  }

  let minPrice = parseFloat(services[0].price)

  for (let i = 1; i < services.length; i++) {
    const currentPrice = parseFloat(services[i].price)
    if (!isNaN(currentPrice) && currentPrice < minPrice) {
      minPrice = currentPrice
    }
  }

  return minPrice
}

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price)
    return { formattedPrice, priceTitle: formattedPrice }
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      )
    }
  }
  return {}
}

export const servicesCategories = [
  'pub_anti_wrinkle_solution',
  'pub_fillerPrice',
  'pub_pdo_threads',
  'pub_bioremodeling',
  'pub_skin_boosters',
  'pub_fat_dissolving_injections',
  'pub_prp_injections'
]

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, {
  loadAfterInitialRendering: 3000
})

export const ListingCardComponent = (props) => {
  const config = useConfiguration()
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing, showAuthorInfo } =
    props

  const location = useLocation()
  const search = parse(location.search)
  const pubKeys = Object.keys(search).filter(
    (key) => key.startsWith('pub_') && servicesCategories.includes(key)
  )
  const hasAnyServiceSelected = pubKeys.length > 0
  const firstSelectedService = hasAnyServiceSelected ? pubKeys[0].slice(4) : null

  const classes = classNames(rootClassName || css.root, className)
  const currentListing = ensureListing(listing)
  const id = currentListing.id.uuid
  const { title = '', publicData } = currentListing.attributes
  const services = publicData?.pricing
  const slug = createSlug(title)
  const author = ensureUser(listing.author)
  const authorName = author.attributes.profile.displayName
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null

  const firstAntiWrinkleSelected = firstSelectedService && firstSelectedService.includes('anti_')
  const minAnitWrinklePrice = firstAntiWrinkleSelected && publicData?.anti_wrinkle_solution

  const priceIfAny =
    minAnitWrinklePrice ||
    (isArrayLength(services) && services?.find((s) => s.service === firstSelectedService)?.price)

  const averageRating = listing.attributes.metadata?.averageRating ?? 0
  const numReviews = listing.attributes.metadata?.numReviews ?? 0
  const renderRatings =
    averageRating && averageRating > 0 ? (
      <div className={css.ratingSection}>
        <SectionStars rating={Number(averageRating)} />
        <span className={css.reviewLength}>
          ({numReviews} {numReviews === 1 ? 'Review' : 'Reviews'})
        </span>
      </div>
    ) : null

  const special_offers_enum = listing.attributes.publicData?.special_offers_enum
  const renderSpecialOffers = !!special_offers_enum && special_offers_enum !== 'no_offer' && (
    <SpecialOfferImageBannner specialOfferEnum={special_offers_enum} />
  )

  const calculatePrice = (priceValue, isFirstAntiWrinkleSelected) => {
    if (!priceValue) {
      return {}
    }

    const priceInCents = isFirstAntiWrinkleSelected ? priceValue : priceValue * 100
    return new Money(priceInCents, 'AUD')
  }

  const price = calculatePrice(priceIfAny, firstAntiWrinkleSelected)

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card'
  } = config.layout.listingImage
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter((k) => k.startsWith(variantPrefix))
    : []

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl)

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null)
      }
    : null

  return (
    <NamedLink
      className={classes}
      name="ListingPage"
      params={{ id, slug }}
      to={{ search: `backTo=${encodeURIComponent(location.pathname + location.search)}` }}
    >
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <div style={{ position: 'relative', zIndex: '2' }}>{renderSpecialOffers}</div>
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>

      <div className={css.info}>
        {priceIfAny ? (
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {firstAntiWrinkleSelected ? 'Starting from' : null} {formattedPrice}
            </div>
          </div>
        ) : null}
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
          <div>{renderRatings}</div>
        </div>
      </div>
    </NamedLink>
  )
}

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true
}

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func
}

export default injectIntl(ListingCardComponent)
