import { mapListingsToCommonStructure } from '../../../components/mui/utils/practitionerListing'
import { selectFavouriteListingIds } from '../../../ducks/user.duck'
import { listingIndex, listingRatingDescIndex } from '../../../lib/algolia'
import { fetchConsultRequests as fetchConsultRequestsApi } from '../../../util/api'

// ================ ACTION TYPES ================ //

export const FETCH_CONSULT_REQUESTS_REQUEST = 'app/PostConcernPage/FETCH_CONSULT_REQUESTS_REQUEST'
export const FETCH_CONSULT_REQUESTS_SUCCESS = 'app/PostConcernPage/FETCH_CONSULT_REQUESTS_SUCCESS'
export const FETCH_CONSULT_REQUESTS_ERROR = 'app/PostConcernPage/FETCH_CONSULT_REQUESTS_ERROR'

export const SET_CONSULT_REQUESTS = 'app/PostConcernPage/SET_CONSULT_REQUESTS'

export const SET_FAVORITE_LISTING_IDS = 'app/PostConcernPage/SET_FAVORITE_LISTING_IDS'

export const SET_PRACTITIONER_REPLIES_SORT_OPTION =
  'app/PostConcernPage/SET_PRACTITIONER_REPLIES_SORT_OPTION'

export const SET_CURRENTLY_OPEN_CONSULT_REQUEST =
  'app/PostConcernPage/SET_CURRENTLY_OPEN_CONSULT_REQUEST'
export const FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_REQUEST =
  'app/PostConcernPage/FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_REQUEST'
export const FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_SUCCESS =
  'app/PostConcernPage/FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_SUCCESS'
export const FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_ERROR =
  'app/PostConcernPage/FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_ERROR'
export const SET_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES =
  'app/PostConcernPage/SET_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES'

// ================ REDUCER ================ //

const initialState = {
  activeConsultRequests: [],
  closedConsultRequests: [],
  isFetchConsultRequestsInProgress: false,
  isCloseConsultRequestInProgress: false,
  practitionerRepliesSortOption: 'recommended',
  currentlyOpenConsultRequest: null,
  currentlyOpenConsultRequestReplies: [],
  isFetchConsultRequestRepliesInProgress: false,
  fetchConsultRequestRepliesError: null
}

const postConcernPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_CONSULT_REQUESTS_REQUEST:
      return {
        ...state,
        isFetchConsultRequestsInProgress: true
      }
    case FETCH_CONSULT_REQUESTS_SUCCESS:
      return {
        ...state,
        isFetchConsultRequestsInProgress: false,
        activeConsultRequests: payload.active,
        closedConsultRequests: payload.closed
      }
    case FETCH_CONSULT_REQUESTS_ERROR:
      return {
        ...state,
        isFetchConsultRequestsInProgress: false
      }
    case SET_CONSULT_REQUESTS:
      return {
        ...state,
        activeConsultRequests: payload.active,
        closedConsultRequests: payload.closed
      }
    case SET_PRACTITIONER_REPLIES_SORT_OPTION:
      return { ...state, practitionerRepliesSortOption: payload }
    case SET_CURRENTLY_OPEN_CONSULT_REQUEST: {
      return { ...state, currentlyOpenConsultRequest: payload }
    }
    case FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_REQUEST:
      return { ...state, isFetchConsultRequestRepliesInProgress: true }
    case FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_SUCCESS:
      return {
        ...state,
        isFetchConsultRequestRepliesInProgress: false,
        currentlyOpenConsultRequestReplies: payload
      }
    case FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_ERROR:
      return {
        ...state,
        isFetchConsultRequestRepliesInProgress: false,
        fetchConsultRequestRepliesError: payload
      }
    case SET_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES:
      return {
        ...state,
        currentlyOpenConsultRequestReplies: payload
      }
    default:
      return state
  }
}

export default postConcernPageReducer

// ================ ACTION CREATORS ================ //

export const fetchConsultRequestsRequest = () => ({
  type: FETCH_CONSULT_REQUESTS_REQUEST
})

export const fetchConsultRequestsSuccess = (consultRequests) => ({
  type: FETCH_CONSULT_REQUESTS_SUCCESS,
  payload: consultRequests
})

export const fetchConsultRequestsError = (error) => ({
  type: FETCH_CONSULT_REQUESTS_ERROR,
  payload: error
})

export const setConsultRequests = (consultRequests) => ({
  type: SET_CONSULT_REQUESTS,
  payload: consultRequests
})

export const setPractitionerRepliesSortOption = (sortOption) => ({
  type: SET_PRACTITIONER_REPLIES_SORT_OPTION,
  payload: sortOption
})

export const setCurrentlyOpenConsultRequest = (consultRequest) => ({
  type: SET_CURRENTLY_OPEN_CONSULT_REQUEST,
  payload: consultRequest
})

export const fetchCurrentlyOpenConsultRequestRepliesRequest = () => ({
  type: FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_REQUEST
})

export const fetchCurrentlyOpenConsultRequestRepliesSuccess = (replies) => ({
  type: FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_SUCCESS,
  payload: replies
})

export const fetchCurrentlyOpenConsultRequestRepliesError = (error) => ({
  type: FETCH_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES_ERROR,
  payload: error
})

export const setCurrentlyOpenConsultRequestReplies = (replies) => ({
  type: SET_CURRENTLY_OPEN_CONSULT_REQUEST_REPLIES,
  payload: replies
})

// ================ SELECTORS ================ //

export const selectPostConcernPage = (state) => state.PostConcernPage

export const selectActiveConsultRequests = (state) =>
  selectPostConcernPage(state).activeConsultRequests

export const selectClosedConsultRequests = (state) =>
  selectPostConcernPage(state).closedConsultRequests

export const selectIsFetchConsultRequestsInProgress = (state) =>
  selectPostConcernPage(state).isFetchConsultRequestsInProgress

export const selectPractitionerRepliesSortOption = (state) =>
  selectPostConcernPage(state).practitionerRepliesSortOption

export const selectCurrentlyOpenConsultRequest = (state) =>
  selectPostConcernPage(state).currentlyOpenConsultRequest

export const selectCurrentlyOpenConsultRequestReplies = (state) =>
  selectPostConcernPage(state).currentlyOpenConsultRequestReplies

export const selectIsFetchConsultRequestRepliesInProgress = (state) =>
  selectPostConcernPage(state).isFetchConsultRequestRepliesInProgress

// ================ THUNKS ================ //

export const fetchConsultRequests = () => async (dispatch) => {
  dispatch(fetchConsultRequestsRequest())

  try {
    const requestsResponse = await fetchConsultRequestsApi()
    const requests = requestsResponse.response

    const mappedActiveConsultRequests = await mapConsultRequestsToCommonStructure(requests.active)
    const mappedClosedConsultRequests = await mapConsultRequestsToCommonStructure(requests.closed)

    dispatch(
      fetchConsultRequestsSuccess({
        active: mappedActiveConsultRequests,
        closed: mappedClosedConsultRequests
      })
    )
  } catch (error) {
    console.error('Error fetching consult requests:', error)
    dispatch(fetchConsultRequestsError(error))
  }
}

export const fetchPractitionerReplies = () => async (dispatch, getState) => {
  const state = getState()
  const activeConsultRequests = selectActiveConsultRequests(state)
  const closedConsultRequests = selectClosedConsultRequests(state)
  const currentlyOpenConsultRequest = selectCurrentlyOpenConsultRequest(state)
  const practitionerRepliesSortOption = selectPractitionerRepliesSortOption(state)
  const favoriteListingIds = selectFavouriteListingIds(state)

  if (!currentlyOpenConsultRequest) {
    return
  }

  dispatch(fetchCurrentlyOpenConsultRequestRepliesRequest())

  try {
    const allRequests = [...activeConsultRequests, ...closedConsultRequests]

    const targetConsultRequest = allRequests.find(
      (request) => request.id === currentlyOpenConsultRequest.id
    )

    const practitionerReplies = targetConsultRequest.practitionerReplies
    const mappedReplies = await mapPractitionerRepliesToCommonStructure(practitionerReplies, {
      practitionerRepliesSortOption,
      favoriteListingIds,
      location: currentlyOpenConsultRequest.location
    })

    dispatch(fetchCurrentlyOpenConsultRequestRepliesSuccess(mappedReplies))
  } catch (err) {
    console.error('Error fetching practitioner replies:', err)
    dispatch(fetchCurrentlyOpenConsultRequestRepliesError(err))
  }
}

export const mapConsultRequestsToCommonStructure = async (requests) => {
  return Promise.all(
    requests.map(async (request) => {
      const location = {
        lat: request.location.latitude,
        lon: request.location.longitude
      }

      return {
        id: request.id,
        createdAt: request.createdAt,
        status: request.archive ? 'closed' : 'active',
        clientId: request.parentReference.User,
        goalsConcerns: request.goalsConcerns,
        address: request.address,
        city: request.city,
        state: request.state,
        zipCode: request.zipCode,
        country: request.country,
        budget: request.budget,
        description: request.description,
        weekdayAvailability: request.weekdayAvailability,
        weekendAvailability: request.weekendAvailability,
        budgetCents: request.budgetCents,
        images: request.photos?.map((photo) => photo.url),
        practitionerReplies: request.replies,
        location
      }
    })
  ).catch((err) => {
    console.error('Error mapping consult request replies:', err)
    return []
  })
}

const mapPractitionerRepliesToCommonStructure = async (
  replies,
  { practitionerRepliesSortOption, favoriteListingIds, location }
) => {
  if (replies.length > 0) {
    try {
      const listingIds = replies.map((reply) => reply.listingId)
      const listings = await fetchPractitionerListings(listingIds, {
        practitionerRepliesSortOption,
        location
      })
      let mappedListings = mapListingsToCommonStructure(listings)

      if (practitionerRepliesSortOption === 'favouritesFirst') {
        const favoriteListings = mappedListings.filter((listing) =>
          favoriteListingIds.includes(listing.id)
        )
        const otherListings = mappedListings.filter(
          (listing) => !favoriteListingIds.includes(listing.id)
        )

        mappedListings = [...favoriteListings, ...otherListings]
      }

      return mappedListings.map((listing) => ({
        listing,
        coverLetter: replies.find((reply) => reply.listingId === listing.id).coverLetter
      }))
    } catch (err) {
      console.error('Error mapping practitioner replies: ', err)
      return []
    }
  }

  return []
}

const fetchPractitionerListings = async (
  listingIds,
  { practitionerRepliesSortOption, location }
) => {
  const filters = `(${listingIds.map((listingId) => `objectID:${listingId}`).join(' OR ')})`

  try {
    if (practitionerRepliesSortOption === 'topRated') {
      const response = await listingRatingDescIndex.search('', { filters })
      return response.hits
    }

    if (practitionerRepliesSortOption === 'nearest') {
      const aroundLocation = `${location.lat}, ${location.lon}`
      const response = await listingIndex.search('', {
        filters,
        aroundLatLng: aroundLocation,
        aroundRadius: 'all'
      })
      return response.hits
    }

    const response = await listingIndex.search('', {
      filters
    })

    return response.hits
  } catch (err) {
    console.error('Error fetching practitioner listings:', err)
    return []
  }
}
