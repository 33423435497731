import React, { useEffect, useMemo, useRef, useState } from 'react'

// Import modules from this directory
import {
  faCircleCheck,
  faCircleDot,
  faCircleExclamation,
  faLock
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { Delete, ExpandMore } from '@material-ui/icons'
import { Stack } from '@mui/material'
import { Field } from 'react-final-form'
import appSettings from '../../config/settings'
import { useConfiguration } from '../../context/configurationContext'
import { useFileUpload } from '../../context/fileUploadContext'
import { filterDictionary } from '../../util/dictionary'
import { ProductList } from '../../util/treatment'
import { SecondaryButtonInline } from '../Button/Button'
import Chip from '../Chip/Chip'
import DropzoneUploader from '../DropzoneUploader/DropzoneUploader'
import FieldCurrencyInput from '../FieldCurrencyInput/FieldCurrencyInput'
import { H4 } from '../Heading/Heading'
import TreatmentPicker from '../TreatmentPicker/TreatmentPicker'

const TreatmentPricingInput = ({ p, treatmentConfig, selectedProducts, setSelectedProducts }) => {
  const product = ProductList.find((product) => product.id === p)

  return (
    product && (
      <Box key={product.id}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Box>{product.name}</Box>
            <Box
              style={{
                fontSize: 'smaller',
                color: 'GrayText'
              }}
            >
              Starting price per {treatmentConfig.priceUnit}
            </Box>
          </Box>
          <Box display="flex">
            <IconButton
              onClick={() => {
                setSelectedProducts(selectedProducts.filter((s) => s !== product.id))
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
        <FieldCurrencyInput
          name={`treatment.${treatmentConfig.id}.medical.${product.id}`}
          placeholder={`Starting price per ${treatmentConfig.priceUnit}`}
          currencyConfig={appSettings.getCurrencyFormatting('USD')}
          id={`treatment.${treatmentConfig.id}.medical.${product.id}`}
          style={{ marginBottom: 14 }}
        />
      </Box>
    )
  )
}

const PhotoUploader = ({
  dropzoneUploaderRef,
  config,
  listingId,
  treatmentConfigId,
  files,
  setFiles,
  photos,
  handleDelete
}) => {
  const deleteButtonStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#FF7070', // Or any color you prefer
    color: 'white',
    border: 'none',
    cursor: 'pointer'
  }

  return (
    <Grid item xs={12} style={{ marginTop: 20 }}>
      Before / Afters
      <DropzoneUploader
        ref={dropzoneUploaderRef}
        firebaseConfig={config.firebaseConfig}
        filePath={`pub/Listing/${listingId}/Treatment/${treatmentConfigId}`}
        files={files}
        setFiles={setFiles}
        showToast={false}
        dropzone={true}
      />
      <Box style={{ marginTop: 24 }}>
        {files.length > 0 && photos.length > 0 && 'Existing Photos'}
        <ImageList cols={2} rowHeight={164}>
          {photos.map((item) => (
            <ImageListItem key={item.bucketFileName}>
              <img srcSet={item.url} src={item.url} alt={item.bucketFileName} loading="lazy" />
              <button
                style={deleteButtonStyle}
                onClick={(e) => {
                  handleDelete(item)
                  e.preventDefault()
                }}
              >
                X
              </button>
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Grid>
  )
}

const TreatmentPricing = ({
  values,
  treatmentConfig,
  form,
  listing,
  expanded,
  setExpanded,
  handleChange,
  selectedTreatments,
  setSelectedTreatments,
  ...props
}) => {
  const { key } = props
  const getTreatmentValues = () => {
    return values.treatment && values.treatment[treatmentConfig.id]
      ? values.treatment[treatmentConfig.id]
      : null
  }

  const dropzoneUploaderRef = useRef(null)
  const { addUploadFn } = useFileUpload()

  useMemo(() => {
    if (addUploadFn) {
      addUploadFn(treatmentConfig.id, async () => {
        return dropzoneUploaderRef.current?.uploadAllFiles()
      })
    }
  }, [])

  const treatmentValues = getTreatmentValues()
  const [priceType, setPriceType] = useState(treatmentValues?.type ?? null)
  const [files, setFiles] = useState([])
  const [photos, setPhotos] = useState(
    treatmentConfig.medical
      ? treatmentValues?.medical?.photos || []
      : treatmentValues?.publicPhotos || []
  )
  const config = useConfiguration()

  const [productPickerOpen, setProductPickerOpen] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState(
    Object.keys(treatmentValues?.medical || {}).filter((p) =>
      ProductList.find((product) => product.id === p)
    )
  )
  const handleProductPickerClose = (newValues) => {
    setProductPickerOpen(false)
    if (newValues) {
      setSelectedProducts(newValues)
    }
  }

  useEffect(() => {
    if (treatmentValues && treatmentValues.medical) {
      treatmentValues.medical = filterDictionary(treatmentValues.medical, selectedProducts)
    }
  }, [selectedProducts])

  const handlePhotosDelete = (item) => {
    setPhotos(photos.filter((photo) => photo.bucketFileName !== item.bucketFileName))
  }

  useEffect(() => {
    if (treatmentConfig.medical && treatmentValues && treatmentValues.medical) {
      form.change(`treatment.${treatmentConfig.id}.medical.photos`, photos)
    } else if (!treatmentConfig.medical) {
      form.change(`treatment.${treatmentConfig.id}.publicPhotos`, photos)
    }
  }, [photos])

  return (
    <>
      <Accordion
        key={key}
        expanded={expanded === treatmentConfig.id}
        onChange={handleChange(treatmentConfig.id)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box display="flex" alignItems="center">
            {expanded === treatmentConfig.id ? (
              <FontAwesomeIcon icon={faCircleDot} />
            ) : treatmentValues?.type ? (
              <FontAwesomeIcon icon={faCircleCheck} color={'#32CD32'} />
            ) : (
              <FontAwesomeIcon icon={faCircleExclamation} color="#ff0000" />
            )}
            <Box marginLeft={1}>
              <Box>{treatmentConfig.name}</Box>
              {!expanded && !treatmentValues?.type && (
                <Box>
                  <Typography variant="caption" style={{ color: 'red' }}>
                    Add pricing info
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'block' }}>
          <Box style={{ padding: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl>
                  <Box display="flex" alignItems="center">
                    <Box marginRight={1}>{treatmentConfig.name} Price</Box>
                    <Chip label="Required" color="#ff7070" borderColor="#ff7070" />
                  </Box>
                  <Field name={`treatment.${treatmentConfig.id}.type`} type="radio">
                    {({ input }) => (
                      <RadioGroup
                        {...input}
                        aria-labelledby="radio-buttons-group-price-label"
                        name="radio-buttons-group-price"
                        onChange={(e) => {
                          setPriceType(e.target.value)
                          form.change(`treatment.${treatmentConfig.id}.type`, e.target.value)
                        }}
                        value={priceType}
                      >
                        <FormControlLabel
                          value="free"
                          control={<Radio />}
                          label="Free"
                          style={{ paddingBottom: 0 }}
                        />
                        <FormControlLabel
                          value="contact-us"
                          control={<Radio />}
                          label="Contact Us"
                          style={{ paddingBottom: 0 }}
                        />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={2}>
                            <FormControlLabel
                              value="price"
                              control={<Radio />}
                              label="Price"
                              style={{ paddingBottom: 0 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <Box display="flex">
                              <FieldCurrencyInput
                                name={`treatment.${treatmentConfig.id}.base.start`}
                                placeholder="Starting From"
                                disabled={priceType !== 'price'}
                                currencyConfig={appSettings.getCurrencyFormatting('USD')}
                              />
                              <Box alignContent={'center'} marginLeft={1} marginRight={1}>
                                -
                              </Box>
                              <FieldCurrencyInput
                                name={`treatment.${treatmentConfig.id}.base.end`}
                                placeholder="To (optional)"
                                currencyConfig={appSettings.getCurrencyFormatting('USD')}
                                disabled={priceType !== 'price'}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    )}
                  </Field>
                </FormControl>
              </Grid>

              {/* Intentionally turned off for now - 8/29/24 */}
              {false &&
                (treatmentConfig.medical ? (
                  <Paper
                    elevation={1}
                    style={{ padding: 20, margin: 10, backgroundColor: '#fcfcfc' }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack>
                          <Box marginTop={2}>
                            <H4>
                              <FontAwesomeIcon icon={faLock} color="#ff7070" />{' '}
                              {treatmentConfig.medicalName} Locked Information
                            </H4>
                          </Box>
                          <span style={{ fontWeight: 'bold', marginBottom: 10 }}>
                            This information is not publicly visible.
                          </span>
                          <p style={{ fontWeight: 'normal', marginTop: 0, marginBottom: 10 }}>
                            It is available to clients only after you have approved them for{' '}
                            {treatmentConfig.medicalName} during an online consult or following a
                            Stella Concierge appointment where you were a recommended practitioner.
                          </p>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignContent="center">
                          <div style={{ display: 'block', alignContent: 'center' }}>
                            Product Price List
                          </div>
                          <SecondaryButtonInline
                            style={{ margin: 0 }}
                            onClick={(e) => {
                              setProductPickerOpen(true)
                              e.preventDefault()
                            }}
                          >
                            + Add
                          </SecondaryButtonInline>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={2}>
                          <FormControl>
                            {selectedProducts.map((p) => (
                              <div key={p}>
                                <TreatmentPricingInput
                                  p={p}
                                  treatmentConfig={treatmentConfig}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              </div>
                            ))}
                          </FormControl>
                        </Stack>
                      </Grid>
                      <PhotoUploader
                        dropzoneUploaderRef={dropzoneUploaderRef}
                        config={config}
                        listingId={listing.id.uuid}
                        treatmentConfigId={treatmentConfig.id}
                        files={files}
                        setFiles={setFiles}
                        photos={photos}
                        handleDelete={handlePhotosDelete}
                      />
                    </Grid>
                  </Paper>
                ) : (
                  <PhotoUploader
                    dropzoneUploaderRef={dropzoneUploaderRef}
                    config={config}
                    listingId={listing.id.uuid}
                    treatmentConfigId={treatmentConfig.id}
                    files={files}
                    setFiles={setFiles}
                    photos={photos}
                    handleDelete={handlePhotosDelete}
                  />
                ))}
              <Grid item xs={12}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <IconButton
                    onClick={(e) => {
                      form.change(`treatment.${treatmentConfig.id}`, {})
                      setSelectedTreatments(
                        selectedTreatments.filter((t) => t !== treatmentConfig.id)
                      )
                      e.preventDefault()
                    }}
                  >
                    <Delete />
                  </IconButton>

                  <SecondaryButtonInline
                    onClick={(e) => {
                      setExpanded(null)
                      e.preventDefault()
                    }}
                    style={{ margin: 0 }}
                  >
                    Save
                  </SecondaryButtonInline>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      <TreatmentPicker
        id="treatment-picker"
        keepMounted
        title="Select Your Products"
        options={ProductList.map((product) => {
          return {
            option: product.id,
            label: product.name,
            category: ''
          }
        })}
        open={productPickerOpen}
        onClose={handleProductPickerClose}
        value={selectedProducts}
      />
    </>
  )
}

export default TreatmentPricing
