import { Box, Typography } from '@material-ui/core'
import { LocationOff } from '@material-ui/icons'

import { useLocationUnavailableBannerStyles } from './hooks'

export const LocationUnavailableBanner = () => {
  const { classes } = useLocationUnavailableBannerStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.locationUnavailableIconContainer}>
        <LocationOff className={classes.locationUnavailableIcon} />
      </Box>
      <Typography className={classes.text}>
        Your current location is unavailable. Adjust your location settings for the best search
        accuracy.
      </Typography>
    </Box>
  )
}
