import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { breakpoints, palette } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 64,
      marginBottom: 0,
      [breakpoints.down('sm')]: {
        marginBottom: 56
      }
    }
  }
})

export const useLegacyBoxStyles = () => {
  const classes = useStyles()
  return { classes }
}
