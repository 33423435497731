/*
 * Renders a set of options with selected and non-selected values.
 *
 * The corresponding component when selecting the values is
 * FieldCheckboxGroup.
 *
 */

import classNames from 'classnames'
import includes from 'lodash/includes'
import PropTypes from 'prop-types'
import React from 'react'

import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { allCourseList } from '../../util/courses'
import { PrimaryButton } from '../Button/Button'
import InfoModal from '../InfoModal/InfoModal'
import css from './PropertyGroup.module.css'

import { AnalyticsEvent, trackEvent } from '../../analytics/handlers'
import exosomes from '../../assets/consultation-images/exosomes.jpeg'
import fat_reduction from '../../assets/consultation-images/fat_reduction.jpeg'
import micro_needling from '../../assets/consultation-images/micro_needling.jpeg'
import muscle_toning from '../../assets/consultation-images/muscle_toning.jpeg'
import pdo_threads from '../../assets/consultation-images/pdo_threads.jpeg'
import pigment_removal from '../../assets/consultation-images/pigment_removal.jpeg'
import skin_tightening from '../../assets/consultation-images/skin_tightening.png'
import { ConsultationModalDescription } from '../ConsultationModal/ConsultationModal'

const checkSelected = (options, selectedOptions) => {
  return options.map((option) => ({
    key: option.key,
    label: option.label,
    isSelected: includes(selectedOptions, option.key),
    list: option.list
  }))
}

const extraOptionsForPopup = allCourseList.map((course) => {
  return {
    key: course.id,
    label: course.trainingProvider,
    description: course.description
  }
})

const ctaButton = (label, url, event) => {
  return (
    <PrimaryButton
      onClick={() => {
        trackEvent(event)
        window.open(url, '_blank')
      }}
    >
      {label}
    </PrimaryButton>
  )
}

const consultationCta = ctaButton(
  'Book a Free Online Consultation',
  'https://infinity-glow.au4.cliniko.com/bookings#service',
  AnalyticsEvent.consultationBookTapped
)
const learnMoreCta = ctaButton(
  'Learn More',
  'https://www.seestella.com.au/online-concierge',
  AnalyticsEvent.consultationLearnMoreTapped
)

const exosomesCta = ctaButton(
  'Learn More',
  'https://www.seestella.com.au/exosomes',
  AnalyticsEvent.consultationLearnMoreTapped
)

const skinTighteningCta = ctaButton(
  'Learn More',
  'https://www.seestella.com.au/skin-tightening',
  AnalyticsEvent.consultationLearnMoreTapped
)

const microneedlingCta = ctaButton(
  'Learn More',
  'https://www.seestella.com.au/micro-needling',
  AnalyticsEvent.consultationLearnMoreTapped
)

const pigmentRemovalCta = ctaButton(
  'Learn More',
  'https://www.seestella.com.au/pigment-removal',
  AnalyticsEvent.consultationLearnMoreTapped
)

const fatReductionCta = ctaButton(
  'Learn More',
  'https://www.seestella.com.au/fat-freezing',
  AnalyticsEvent.consultationLearnMoreTapped
)

const muscleToningCta = ctaButton(
  'Learn More',
  'https://www.seestella.com.au/muscle-toning',
  AnalyticsEvent.consultationLearnMoreTapped
)

const popUpOptionsForConsultations = [
  {
    key: 'wrinkle-management',
    description: ConsultationModalDescription(),
    icon: faCircleInfo,
    cta: consultationCta,
    event: AnalyticsEvent.consultationProfileTapped
  },
  {
    key: 'volume-enhancement',
    description: ConsultationModalDescription(),
    icon: faCircleInfo,
    cta: consultationCta,
    event: AnalyticsEvent.consultationProfileTapped
  },
  {
    key: 'skin-rejuvenation',
    description: ConsultationModalDescription(),
    icon: faCircleInfo,
    cta: consultationCta,
    event: AnalyticsEvent.consultationProfileTapped
  },
  {
    key: 'prp-injections',
    description: ConsultationModalDescription(),
    icon: faCircleInfo,
    cta: consultationCta,
    event: AnalyticsEvent.consultationProfileTapped
  },
  {
    key: 'pdo-threads',
    description:
      'Lift and rejuvenate your skin with minimally invasive dissolvable suture treatments, promoting collagen production.',
    cta: learnMoreCta,
    image: pdo_threads,
    event: AnalyticsEvent.consultationProfileTapped
  },
  {
    key: 'micro-needling',
    description:
      "Stimulate your skin's natural healing with small needles that create controlled micro-injuries to reduce the appearance of fine lines, wrinkles, and scars for smoother, firmer skin.",
    cta: microneedlingCta,
    image: micro_needling,
    event: AnalyticsEvent.consultationProfileTapped
  },
  {
    key: 'exosomes',
    description:
      "Harness the power of regenerative exosomes to enhance your skin's vitality, promoting cellular repair and rejuvenation for a radiant complexion.",
    cta: exosomesCta,
    image: exosomes,
    event: AnalyticsEvent.consultationProfileTapped
  },
  {
    key: 'skin-tightening',
    description:
      'Radiofrequency energy that heats the deeper layers of the skin, stimulating collagen production, tightening existing collagen fibers, and improving skin elasticity, resulting in firmer, smoother skin.',
    cta: skinTighteningCta,
    image: skin_tightening,
    event: AnalyticsEvent.consultationProfileTapped
  },
  {
    key: 'pigment-removal',
    description:
      'Lasers or intense pulsed light (IPL) that target and break down excess melanin in the skin, which is then naturally eliminated by the body, resulting in a more even complexion.',
    cta: pigmentRemovalCta,
    image: pigment_removal,
    event: AnalyticsEvent.consultationProfileTapped
  },
  {
    key: 'muscle-toning',
    description:
      'Electrical impulses to stimulate muscle contractions, effectively strengthening and toning targeted muscle groups, resulting in improved muscle definition and overall body contouring.',
    cta: muscleToningCta,
    image: muscle_toning,
    event: AnalyticsEvent.consultationProfileTapped
  },
  {
    key: 'fat-reduction',
    description:
      'Cryolipolysis or ultrasound targets and permanently breaks down stubborn fat cells, leading to a slimmer and more contoured appearance in treated areas.',
    cta: fatReductionCta,
    image: fat_reduction,
    event: AnalyticsEvent.consultationProfileTapped
  }
]

const IconCheck = (props) => {
  const isVisible = props.isVisible
  const classes = isVisible ? css.checkIcon : classNames(css.checkIcon, css.hidden)

  return (
    <svg width="9" height="9" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path
        className={css.marketplaceFill}
        d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
        fillRule="evenodd"
      />
    </svg>
  )
}

const Item = (props) => {
  const { label, isSelected, showExtraPopup, showList, list } = props
  const labelClass = css.showPopUpText
  const renderLabel = (
    <div className={css.labelWrapper}>
      <span className={labelClass}>{label}</span>
    </div>
  )

  if (!isSelected) {
    return <></>
  }

  return (
    <li className={css.item}>
      <span className={css.iconWrapper}>
        <IconCheck isVisible={isSelected} />
      </span>
      {showExtraPopup?.key && isSelected ? (
        <InfoModal
          renderTriggerComponent={
            <div style={{ display: 'flex' }}>
              {renderLabel}
              <div style={{ color: '#ff7070', marginLeft: '5px', cursor: 'pointer' }}>
                {
                  <FontAwesomeIcon
                    icon={showExtraPopup?.icon ?? faCircleInfo}
                    size="sm"
                    onClick={() => {
                      showExtraPopup?.event && trackEvent(showExtraPopup?.event)
                    }}
                  />
                }
              </div>
            </div>
          }
          title={label}
          description={showExtraPopup?.description}
          image={showExtraPopup?.image}
          list={showList ? list : null}
          cta={showExtraPopup?.cta}
        />
      ) : (
        renderLabel
      )}
    </li>
  )
}

const PropertyGroup = (props) => {
  const { rootClassName, className, id, options, selectedOptions, twoColumns, showList } = props
  const classes = classNames(rootClassName || css.root, className)
  const listClasses = twoColumns ? classNames(classes, css.twoColumns) : classes

  const checked = checkSelected(options, selectedOptions)

  return (
    <ul className={listClasses}>
      {checked.map((option) => (
        <Item
          key={`${id}.${option.key}`}
          label={option.label}
          isSelected={option.isSelected}
          showExtraPopup={
            extraOptionsForPopup?.find((e) => e.key === option.key) ||
            popUpOptionsForConsultations.find((e) => e.key === option.key)
          }
          showList={showList}
          list={showList ? option.list : null}
        />
      ))}
    </ul>
  )
}

PropertyGroup.defaultProps = {
  rootClassName: null,
  className: null,
  selectedOptions: [],
  twoColumns: false
}

const { arrayOf, bool, node, shape, string } = PropTypes

PropertyGroup.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired
    })
  ),
  selectedOptions: arrayOf(string),
  twoColumns: bool
}

export default PropertyGroup
