import { bool, func, string } from 'prop-types'
import React from 'react'
import { AnalyticsEvent, trackEvent } from '../../analytics/handlers.js'
import { PrimaryButton } from '../Button/Button.js'
import Modal from '../Modal/Modal.js'
import css from './ConsultationModal.module.css'

export const ConsultationModalDescription = (
  description = 'Book a free online consult with an expert practitioner for options, advice, pricing guidance, and help finding the right practitioner.'
) => {
  return (
    <div>
      <p>{description}</p>

      <span>
        <strong>How It Works</strong>
        <ol>
          <li>Book a time that suits your schedule.</li>
          <li>Discuss your concerns, goals and lifestyle.</li>
          <li>
            Get treatment options, pricing information and recommended practitioners near you.
          </li>
        </ol>
      </span>

      <p>
        Questions? Check out this page to{' '}
        <a
          onClick={() => {
            trackEvent(AnalyticsEvent.consultationLearnMoreTapped)
            window.open('https://www.seestella.com.au/online-concierge', '_blank')
          }}
        >
          Learn More
        </a>
        .
      </p>
      <i style={{ fontSize: 'smaller' }}>
        <strong>
          Stella will not spam, email or contact you regarding treatment options after your consult.
        </strong>
      </i>
    </div>
  )
}

const ConsultationModal = ({
  id,
  isOpen,
  setIsOpen,
  onManageDisableScrolling,
  usePortal = false,
  description,
  title = 'Free Online Concierge',
  buttonTitle = 'Book a Free Online Consultation'
}) => {
  const closeModal = () => setIsOpen(false)

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onClose={closeModal}
      usePortal={usePortal}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.bookingModalContent}>
        <div className={css.modalTitle}>{title}</div>
        <div>{ConsultationModalDescription(description)}</div>
        <div style={{ marginTop: '20px' }}>
          <PrimaryButton
            onClick={() => {
              trackEvent(AnalyticsEvent.consultationBookTapped)
              window.open('https://infinity-glow.au4.cliniko.com/bookings#service', '_blank')
            }}
          >
            {buttonTitle}
          </PrimaryButton>
          <div style={{ marginTop: 10 }}>
            <p style={{ fontSize: 'smaller' }}>
              <i>
                For more info on Australian regulations, we've written up{' '}
                <a
                  onClick={() => {
                    trackEvent(AnalyticsEvent.consultationLearnMoreTapped)
                    window.open('https://www.seestella.com.au/regulations', '_blank')
                  }}
                >
                  this page
                </a>{' '}
                to help.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ConsultationModal.defaultProps = {
  isOpen: false
}

ConsultationModal.propTypes = {
  id: string.isRequired,
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  onManageDisableScrolling: func.isRequired
}

export default ConsultationModal
