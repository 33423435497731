import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      height: '31px',
      paddingTop: '6px',
      paddingBottom: '6px',
      paddingLeft: '16px',
      paddingRight: '16px',
      borderRadius: '4px',
      backgroundColor: ({ isActive }) => (isActive ? palette.primary.light : palette.common.white)
    },
    text: {
      fontSize: '16px',
      lineHeight: '20px',
      color: ({ isActive }) => (isActive ? palette.primary.main : palette.blue['800']),
      padding: 0,
      letterSpacing: 0
    }
  }
})

export const useDesktopConsultationListItemStyles = ({ isActive }) => {
  const classes = useStyles({ isActive })
  return { classes }
}
