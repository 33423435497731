import { Box } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useIconStyles } from './hooks'

export const Icon = ({ icon, iconSize = '1x', onClick, onMouseDown, className }) => {
  const isClickable = onClick
  const { joinedClasses } = useIconStyles({ className, isClickable })

  return (
    <Box className={joinedClasses} onClick={onClick} onMouseDown={onMouseDown}>
      <FontAwesomeIcon icon={icon} size={iconSize} />
    </Box>
  )
}
