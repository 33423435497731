import algoliasearch from 'algoliasearch'

export const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
)
export const consultationIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_CONSULTATION_INDEX_NAME
)
export const listingIndex = client.initIndex(process.env.REACT_APP_ALGOLIA_LISTING_INDEX_NAME)
export const listingRatingDescIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_LISTING_RATING_INDEX_NAME
)

export const getDataForConsultations = async (query) => {
  try {
    const consultation = await consultationIndex.search(query)

    if (query === '') {
      return { consultation, listing: { hits: [] } }
    }

    const listing = await listingIndex.search(query)
    return { consultation, listing }
  } catch (err) {
    console.error('Failed to fetch consultations from Algolia', err)
    return {
      consultation: {
        hits: []
      },
      listing: {
        hits: []
      }
    }
  }
}

export const getAllConsultations = async () => {
  try {
    const consultations = await consultationIndex.search('')
    return consultations.hits
  } catch (err) {
    console.error('Failed to fetch consultations from Algolia', err)
    return []
  }
}
